// initial state
const state = {
    is_loading: true,
    new_food: {},
    recommend_foods: [],
    show_detail: false
};

// getters
const getters = {
    get_recommend_1(state) {
        return state.recommend_foods.slice(0, 2);
    },
    get_recommend_2(state) {
        return state.recommend_foods.slice(2);
    }
};

// actions
const actions = {
    initData({ commit, rootState }, sid) {
        let aj_host = rootState.apiHost;
        fetch(aj_host + '/weapp/user/homemodule/get?sid=' + sid, {
            method: 'GET'
        })
            .then((res) => {
                return res.text();
            })
            .then((res) => {
                let res_data = JSON.parse(res).data;

                if (res_data.code === 100000) {
                    let _food_list = res_data.data;

                    let recommend_arr = [];
                    let new_food = {};
                    for (var i = 0; i < _food_list.length; i++) {
                        if (_food_list[i].is_new == 1) {
                            new_food = _food_list[i];
                        } else if (_food_list[i].is_recommend == 1) {
                            recommend_arr.push(_food_list[i]);
                        }
                    }

                    commit('INIT_HOME_DATA', {
                        new_food: new_food,
                        recommend_foods: recommend_arr
                    });
                }
            });
    },
    doToggleDetail({ commit, rootState }, isSHow) {
        commit('HOME_TOGGLE_DETAIL', isSHow);
    }
};

// mutations
const mutations = {
    INIT_HOME_DATA(state, res_data) {
        state.new_food = res_data.new_food;
        state.recommend_foods = res_data.recommend_foods;
        state.is_loading = false;
    },
    HOME_TOGGLE_DETAIL(state, show) {
        state.show_detail = show;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
