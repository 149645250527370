var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row",on:{"click":_vm.choose_food}},[_c('div',{staticClass:"col-sm-9 pr-0"},[_c('h1',[_c('span',{staticClass:"item-title text-truncate"},[_vm._v(" "+_vm._s(_vm.translate('item', _vm.product_item.fname))+" ")]),(_vm.cart_food_dict[_vm.product_item.fid] && _vm.cart_food_dict[_vm.product_item.fid].mix_fcount > 0)?_c('span',[_vm._v(" ( "),_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(_vm.cart_food_dict[_vm.product_item.fid].mix_fcount))]),_vm._v(" ) ")]):_vm._e()]),_c('div',{staticClass:"pt-3 pb-3 text-truncate",staticStyle:{"color":"black","word-break":"break-word","line-height":"1.4"}},[_c('div',{staticClass:"desc_item",domProps:{"innerHTML":_vm._s(_vm.desc)}})]),(_vm.product_item.fprice || _vm.product_item.is_tbd == 1)?_c('h2',{staticClass:"price",class:{ price_tbd: _vm.product_item.is_tbd }},[(_vm.product_item.is_tbd)?_c('div',{staticClass:"tbd"},[_vm._v("TBD")]):[_c('div',{class:['fprice', _vm.ifShowFpriceOriginal(_vm.product_item) && 'promo']},[_vm._v(" $"+_vm._s(_vm.product_item.fprice)+" ")]),(_vm.ifShowFpriceOriginal(_vm.product_item))?_c('div',{staticClass:"fprice_original"},[_vm._v(" $"+_vm._s(_vm.product_item.fprice_original)+" ")]):_vm._e()],(_vm.product_item.feature_name && !_vm.product_item.addon_id)?_c('span',{staticClass:"d-inline-block"},[_vm._v("with options")]):_vm._e(),(_vm.product_item.addon_id && !_vm.product_item.feature_name)?_c('span',{staticClass:"d-inline-block"},[_vm._v("with addons")]):_vm._e(),(_vm.product_item.feature_name && _vm.product_item.addon_id)?_c('span',{staticClass:"d-inline-block"},[_vm._v("with addons & options")]):_vm._e()],2):_vm._e(),(_vm.product_item.daily_limit > 0 && _vm.product_item.is_out_of_stock !== 1)?_c('div',{class:[
                    'daily_limit',
                    _vm.product_item.daily_limit - _vm.product_item.daily_sales_count <= 0 && 'sale_out'
                ]},[_vm._v(" "+_vm._s(_vm.dailyLimitLeft)+" ")]):_vm._e(),(_vm.product_item.sc_order_limit > 0 || _vm.product_item.sc_sub_order_limit > 0)?_c('div',{staticClass:"once_limit"},[_vm._v(" "+_vm._s(_vm.onceLimitTip)+" ")]):_vm._e(),(!_vm.is_active)?_c('div',{staticClass:"product-extra"},[_c('span',[_vm._v("Not available")])]):_vm._e(),(_vm.item.is_active && _vm.product_item.is_out_of_stock == 1)?_c('div',{staticClass:"product-extra",on:{"click":_vm.showOutOfStock}},[_c('span',{staticClass:"out-of-stock"},[_vm._v(" "+_vm._s(_vm.$t('menuItem.outof_stock'))+" ")])]):_vm._e()]),_c('div',{staticClass:"product_item_right"},[(_vm.product_item.promote_tag)?_c('div',{staticClass:"promote_tag"},[_vm._v(_vm._s(_vm.$t('food.tag_'+_vm.product_item.promote_tag)))]):_vm._e(),_c('div',{directives:[{name:"lazy",rawName:"v-lazy:background-image",value:(_vm.getLazyLoadImg(_vm.product_item)),expression:"getLazyLoadImg(product_item)",arg:"background-image"}],staticClass:"item-img"}),(
                    !_vm.outOfStock &&
                    _vm.product_item.addon_data.length == 0 &&
                    !(_vm.product_item.feature_name && !_vm.product_item.addon_id)
                )?_c('div',{staticClass:"plus-wrap"},[(_vm.cartNum > 1)?_c('img',{attrs:{"src":require("@/assets/img/minus_2.svg"),"alt":""},on:{"click":function($event){$event.stopPropagation();return _vm.do_save(-1)}}}):_vm._e(),(_vm.cartNum === 1)?_c('img',{attrs:{"src":require("@/assets/img/buy_cart_remove.png"),"alt":""},on:{"click":function($event){$event.stopPropagation();return _vm.do_save(-1)}}}):_vm._e(),(_vm.cartNum > 0)?_c('span',{staticClass:"num"},[_vm._v(_vm._s(_vm.cartNum))]):_vm._e(),_c('img',{attrs:{"src":require("@/assets/img/plus.svg"),"alt":""},on:{"click":function($event){$event.stopPropagation();return _vm.do_save(1)}}})]):_vm._e()])]),(_vm.product_item.feature_tag)?_c('div',{staticClass:"food_tag",staticStyle:{"color":"green"}},[_c('div',{staticClass:"img img_leaf"}),_c('div',{staticClass:"food_tag_text"},[_vm._v(" "+_vm._s(_vm.feature_tag)+" ")])]):_vm._e(),(_vm.product_item.allergy_tag)?_c('div',{staticClass:"food_tag"},[_c('div',{staticClass:"img img_alert"}),_c('div',{staticClass:"food_tag_text"},[_vm._v(" "+_vm._s(_vm.allergy_tag)+" ")])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }