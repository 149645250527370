// initial state
const state = {
    address_list: [],
    chosen_aid: '',
    default_address: {},
    address_dict: {},
    is_loading: true
};

// getters
const getters = {};

// actions
const actions = {
    initData({ commit, rootState }, uid) {
        let aj_host = rootState.apiHost;
        fetch(aj_host + '/weapp/user/address/list?uid=' + uid, {
            method: 'GET'
        })
            .then((res) => {
                return res.text();
            })
            .then((res) => {
                let res_data = JSON.parse(res).data;

                if (res_data.code === 100000) {
                    let _address_list = res_data.data;
                    let _chosen_aid = '';
                    let address_dict = {};
                    _address_list = _address_list.map((item) => {
                        item['isTouchMove'] = false;
                        address_dict[item.aid] = item;
                        if (item.is_default === '1') {
                            _chosen_aid = item.aid;
                        }
                        return item;
                    });

                    _chosen_aid = _chosen_aid === '' ? _address_list[0].aid : _chosen_aid;
                    let _defaultAddress = address_dict[_chosen_aid];

                    commit('INIT_ADDRESS_DATA', {
                        address_list: _address_list,
                        chosen_aid: _chosen_aid,
                        default_address: _defaultAddress,
                        address_dict: address_dict
                    });
                }
            });
    },
    setDefault({ commit, rootState }, aid) {
        let aj_host = rootState.apiHost;
        fetch(aj_host + '/weapp/user/address/choose?aid=' + aid, {
            method: 'GET'
        })
            .then((res) => {
                return res.text();
            })
            .then((res) => {
                let res_data = JSON.parse(res).data;

                if (res_data.code === 100000) {
                    commit('SET_DEFAULT_ADDRESS', aid);
                }
            });
    },
    delAddress({ commit, rootState }, post_data) {
        let aj_host = rootState.apiHost;
        fetch(
            aj_host +
                '/weapp/user/address/del?aid=' +
                post_data.aid +
                '&uid=' +
                post_data.uid +
                '&is_default=' +
                post_data.is_default,
            {
                method: 'GET'
            }
        )
            .then((res) => {
                return res.text();
            })
            .then((res) => {
                let res_data = JSON.parse(res).data;

                if (res_data.code === 100000) {
                    commit('DEL_ADDRESS', post_data.index);
                }
            });
    }
};

// mutations
const mutations = {
    INIT_ADDRESS_DATA(state, address_data) {
        state.address_list = address_data.address_list;
        state.chosen_aid = address_data.chosen_aid;
        state.default_address = address_data.default_address;
        state.address_dict = address_data.address_dict;
        state.is_loading = false;
    },
    SET_DEFAULT_ADDRESS(state, aid) {
        state.chosen_aid = aid;
        state.default_address = state.address_dict[aid];
    },
    DEL_ADDRESS(state, _index) {
        state.address_list = [...state.address_list.slice(0, _index), ...state.address_list.slice(_index + 1)];
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
