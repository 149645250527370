import Vue from 'vue';
import Router from 'vue-router';
import store from './store';

const MenuNewPage = () => import('./views/MenuNew.vue');
const OrdersPage = () => import('./views/Orders.vue');
const CartPage = () => import('./views/Cart.vue');
const OrderDetailPage = () => import('./views/OrderDetail.vue');
const SharedOrderDetailPage = () => import('./views/SharedOrderDetail.vue');
const ThanksPage = () => import('./views/Thanks.vue');
const PartyPage = () => import('./views/Party.vue');
const InvitePeoplePage = () => import('./views/InvitePeople.vue');
const FoodDetailLayer = () => import('./views/FoodDetail.vue');
const LandingPrePage = () => import('./views/LandingPre.vue');
const FeedBackPage = () => import('./views/FeedBack.vue');
const SelectTablePage = () => import('./views/SelectTable.vue');
const ClosedTable = () => import('./views/ClosedTable.vue');

const router = new Router({
    mode: 'hash',
    linkExactActiveClass: 'is-active',
    scrollBehavior: () => ({ y: 0 }),
    routes: [
        { path: '/menu', component: MenuNewPage, name: 'menu' },
        { path: '/order', component: OrdersPage, name: 'order' },
        { path: '/sub/cart', component: CartPage, name: 'subCart' },
        { path: '/sub/orderdetail', component: OrderDetailPage },
        { path: '/sub/sharedorderdetail', component: SharedOrderDetailPage, name: 'orders' },
        { path: '/sub/selecttable', component: SelectTablePage },
        { path: '/sub/thanks', component: ThanksPage },
        { path: '/sub/party', component: PartyPage },
        { path: '/invite', component: InvitePeoplePage },
        { path: '/sub/preorder', component: LandingPrePage },
        { path: '/feedback', component: FeedBackPage },
        { path: '/food', component: FoodDetailLayer },
        { path: '/closed_table', component: ClosedTable },

        { path: '*', redirect: '/menu' }
    ]
});

router.beforeEach((to, from, next) => {
    const { app } = store.state;
    const { query } = to;
    if (app.showCartLayer) {
        store.commit('app/TOGGLE_CART_LAYER');
    }
    if (query.sid) {
        const { sid, tid } = query;
        store.commit('app/APP_SET_SID', {
            sid,
            tid
        });
    }
    next();
});

//suppress the same path alert
const originalPush = Router.prototype.push;
const originalReplace = Router.prototype.replace;
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};
Router.prototype.push = function replace(location) {
    return originalReplace.call(this, location).catch((err) => err);
};

Vue.use(Router);

export default router;
