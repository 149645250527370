export function isWeixin() {
    const ua = navigator.userAgent.toLowerCase();
    return ua.match(/MicroMessenger/i) == 'micromessenger';
}

export function isIOS() {
    return navigator.userAgent.match(/(iPhone|iPod|iPad);?/i);
}

export function loadImg(src) {
    return new Promise((rs, rj) => {
        const img = new Image();
        img.onload = rs;
        img.onerror = rj;
        img.src = src;
    });
}

export function lazyLoadImg(img) {
    return {
        src: img,
        loading: require('../assets/images/image-loading.svg')
    };
}
