// initial state
const state = {
    table_list: [],
    is_loading: true
};

// getters
const getters = {};

// actions
const actions = {
    initData({ commit, rootState }, sid) {
        let aj_host = rootState.apiHost;
        fetch(aj_host + '/weapp/user/table/list?sid=' + sid, {
            method: 'GET'
        })
            .then((res) => {
                return res.text();
            })
            .then((res) => {
                res = JSON.parse(res);
                if (!res || !res.data) {
                    console.log('error when init data');
                    return;
                }
                if (res.data.code === 100000) {
                    let table_list = res.data.data;
                    table_list.sort(function (a, b) {
                        return a.tname - b.tname;
                    });
                    for (let i = 0; i < table_list.length; i++) {
                        table_list[i].val = i;
                    }
                    commit('TABLE_INIT_DATA', table_list);
                }
            });
    }
};

// mutations
const mutations = {
    TABLE_INIT_DATA(state, table_list) {
        state.table_list = table_list;
        state.is_loading = false;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
