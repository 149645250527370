// initial state
const state = {
    order_list: [],
    shared_order_list: []
};

// getters
const getters = {
    check_if_has_shared_orders(state) {
        return state.shared_order_list.length > 0 ? false : true;
    },
    check_if_has_orders(state) {
        return state.order_list.length > 0 ? false : true;
    },
    get_order_list(state) {
        return state.order_list;
    },
    get_shared_order_list(state) {
        return state.shared_order_list;
    }
};

// actions
const actions = {
    initData({ commit, rootState }, post_data) {
        let aj_host = rootState.apiHost;
        fetch(
            aj_host +
                '/weapp/user/order/list?sid=' +
                post_data.sid +
                '&uid=' +
                post_data.uid +
                '&oid=&is_shared=0&type=' +
                post_data.type,
            {
                method: 'GET'
            }
        )
            .then((res) => {
                return res.text();
            })
            .then((res) => {
                res = JSON.parse(res);
                if (res.data.code === 100000) {
                    let order_list = res.data.data;
                    commit('INIT_ORDERS_DATA', order_list);
                }
            });

        fetch(
            '/weapp/user/order/list?sid=' +
                post_data.sid +
                '&uid=' +
                post_data.uid +
                '&oid=&is_shared=1&type=' +
                post_data.type,
            {
                method: 'GET'
            }
        )
            .then((res) => {
                return res.text();
            })
            .then((res) => {
                res = JSON.parse(res);
                if (res.data.code === 100000) {
                    let shared_order_list = res.data.data;
                    commit('INIT_SHARED_ORDERS_DATA', shared_order_list);
                }
            });
    }
};

// mutations
const mutations = {
    INIT_ORDERS_DATA(state, order_list) {
        state.order_list = order_list;
    },
    INIT_SHARED_ORDERS_DATA(state, shared_order_list) {
        state.shared_order_list = shared_order_list;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
