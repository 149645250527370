// initial state
const state = {
    name: '',
    address: '',
    tel: '',
    is_loading: true,
    err: '',
    has_feedback: 0
};

// getters
const getters = {
    get_if_has_feedback(state) {
        return state.has_feedback;
    }
};

// actions
const actions = {
    initData({ commit, rootState }, post_data) {
        let aj_host = rootState.apiHost;

        let paramsArr = [];
        for (var o in post_data) {
            paramsArr.push(o + '=' + post_data[o]);
        }

        fetch(aj_host + '/weapp/user/feedback/get?' + paramsArr.join('&'), {
            method: 'GET'
        })
            .then((res) => {
                return res.text();
            })
            .then((res) => {
                let res_data = JSON.parse(res).data;

                if (res_data.code === 100000) {
                    commit('FEEDBACK_INIT_DATA', res_data.data);
                } else {
                    commit(
                        'app/APP_SET_MSG',
                        {
                            msg: res_data.msg
                        },
                        { root: true }
                    );
                }
            });
    },
    doSave({ commit, rootState }, post_data) {
        let aj_host = rootState.apiHost;

        let paramsArr = [];
        for (var o in post_data) {
            if (o != 'router') {
                paramsArr.push(o + '=' + encodeURIComponent(post_data[o]));
            }
        }

        fetch(aj_host + '/weapp/user/feedback/add?' + paramsArr.join('&'), {
            method: 'GET'
        })
            .then((res) => {
                return res.text();
            })
            .then((res) => {
                let res_data = JSON.parse(res).data;

                if (res_data.code === 100000) {
                    commit(
                        'app/setToast',
                        {
                            msg: 'Thanks for your feedback'
                        },
                        { root: true }
                    );
                    commit('FEEDBACK_INIT_DATA', [1]);
                } else {
                    commit(
                        'app/setToast',
                        {
                            msg: res_data.msg
                        },
                        { root: true }
                    );
                }
            });
    }
};

// mutations
const mutations = {
    FEEDBACK_INIT_DATA(state, _data) {
        state.has_feedback = _data && _data.length > 0 ? 1 : 0;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
