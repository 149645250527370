<template>
    <div v-if="store_data.scan_table_enable===0" style="" class="page_unavailable">
        The page you’re looking for can’t be found.
    </div>
    <div id="app" :class="{ landing: is_select }" v-else>
        <Header />
        <div class="mt-cover" v-if="is_select" :style="'backgroundImage:url(' + storeImage + ')'"></div>
        <div class="mt-content mt-c-lg">
            <TipScrollAuto />
            <CategorySwiper v-show="is_menu" id="swiper" />
            <div class="container">
                <keep-alive include="menu_list">
                    <router-view></router-view>
                </keep-alive>
            </div>
        </div>
        <LeftNav />
        <div class="toast-bt" :class="{ centered: msg !== '' }" v-show="msg !== ''" v-html="msg"></div>
        <v-dialog />
        <footer v-if="is_select">powered by <a href="https://minitable.net/" target="_blank">minitable</a></footer>
        <div class="loading" v-show="isLoading">
            <div class="spinner-border text-warning" style="width: 3rem; height: 3rem" role="status"></div>
            <div class="loading-txt" v-if="loadingTxt">{{ loadingTxt }}</div>
        </div>
        <RecommendLayer
            class="popup position-absolute centered"
            :recommendData="recommend_popup_setting"
            :closeFun="hideRecommend"
            v-show="showRecommend && is_select && recommend_popup_setting.recommend_popup_enable === 1"
        />
        <FooterButtons />
    </div>
</template>
<style lang="scss">
@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
.page_unavailable{
    background-color:white;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
}
.vue-dialog-content-title {
    font-weight: 1000;
    font-size: 16px;
}
.vue-dialog-content {
    font-weight: 100;
    font-size: 14px;
}
.top-tip {
    position: fixed;
    top: 50px;
    left: 0;
    width: 100%;
    /* background: #2196F3; */
    background: #1b5bbd;
    opacity: 0.8;
    text-align: center;
    line-height: 30px;
    color: #fff;
    z-index: 10;
    /*text-decoration: underline;*/
}

.mt-content {
    padding-bottom: 130px !important;
}

.toast-bt {
    background-color: rgba(252, 251, 94);
    color: #4a4a4a;
    position: fixed;
    max-width: 80%;
    font-weight: bolder;
    box-sizing: border-box;
    text-align: center;
    z-index: 1000;
    -webkit-transition: opacity 0.3s linear;
    transition: opacity 0.3s linear;
    padding: 20px;
    font-size: 16px;
    flex-basis: 350px;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 0.25rem 0.75rem rgba(30, 32, 34, 0.1);
    border-radius: 0.25rem;
    font-family: $boldFont;

    .toast-icon {
        display: block;
        text-align: center;
        font-size: 56px;
    }

    .toast-icon-success {
        &:before {
            content: '\E646';
        }
    }

    .toast-icon-error {
        &:before {
            content: '\E619';
        }
    }

    .addon-name-toast {
        width: 80%;
        display: inline-block;
    }
}

.centered {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #00000080;
    z-index: 2;
    .loading-txt {
        color: white;
        font-size: 18px;
        margin-top: 14px;
    }
}

.popup {
    position: absolute;
    width: 100%;
    height: 100%;
}
.single_button_fixed {
    font-weight: bold;
    width: calc(100% - 20px);
    position: fixed;
    left: 10px;
    height: 60px;
    display: flex;
    bottom: 85px;
    border-radius: 15px;
    font-size: 18px;
    align-items: center;
    justify-content: center;
    padding: 0;
    background-color: var(--themeColor);
    color: var(--themeColorFont);
    &.disabled {
        position: fixed;
    }
}
</style>

<script>
import { mapState, mapActions, mapMutations, mapGetters, createNamespacedHelpers } from 'vuex';
import LeftNav from '../components/LeftNav';
import TipScrollAuto from '../components/TipScrollAuto.vue';
import CategorySwiper from '../components/CategorySwiper';
import Header from '@/components/Header';
import FooterButtons from '@/components/FooterButtons';
import RecommendLayer from '@/components/RecommendLayer';
import { setLang } from '@/locales';

const App = createNamespacedHelpers('app');
const Menu = createNamespacedHelpers('menu');

export default {
    name: 'app',
    components: {
        FooterButtons,
        LeftNav,
        CategorySwiper,
        Header,
        TipScrollAuto,
        RecommendLayer
    },
    data() {
        return {
            showRecommend: true
        };
    },

    created() {
        setTimeout(() => {
            this.initData();
        }, 100);

        if (this.shared_tid !== '' && this.shared_cart_id !== '') {
            if (this.shared_foods_timer === -1) {
                let temp_timer = setInterval(() => {
                    if (this.cartEditLock) {
                        return;
                    }
                    this.getSharedCart({
                        cartid: this.shared_cart_id,
                        router: this.$router
                    });
                }, 3000);
                this.setSharedTimer(temp_timer);
            }
        }
        if (this.menuTimer < 0) {
            this.loadMenu(this.sid);
            let menuTimer = setInterval(() => {
                this.loadMenu(this.sid);
            }, 30 * 1000);
            this.set_menu_timer(menuTimer);
        }
    },
    mounted() {
        // so that all pages will back to menu page
        // but why? added by wudi @20211014， 增加了一个sid和tid存在的判断
        window.onpopstate = () => {
            if (!document.location.href.includes('menu') || !document.location.hash.match(/\w/)) {
                if (this.$store.state.app.sid && this.$store.state.app.shared_tid) {
                    this.$router.push({ name: 'menu' }).catch((e) => {});
                }
            }
        };
    },
    computed: {
        ...App.mapGetters({
            language: 'get_language',
            msg: 'get_app_msg',
            spic: 'get_store_pic',
            shared_tid: 'get_shared_tid',
            recommend_popup_setting: 'get_recommend_popup_setting'
        }),
        ...App.mapState([
            'cartEditLock',
            'showCartLayer',
            'billCreated',
            'shared_cart_id',
            'shared_foods_timer',
            'isLoading',
            'loadingTxt',
            'sid',
            'menuTimer',
            'store_data'
        ]),
        is_select() {
            return this.$route.path.indexOf('/sub/selecttable') > -1;
        },
        is_menu() {
            return this.$route.path.indexOf('/menu') > -1;
        },
        storeImage() {
            return this.spic || '';
        },
        isDetail() {
            return this.$route.path.indexOf('/food') > -1;
        }
    },
    watch: {
        '$route'() {
            window['__logger__'] && window['__logger__']({
                view: 1,
                click: 0
            });
        },
        language: {
            immediate: true,
            handler: function (val) {
                setLang(val);        
            }
        },
        billCreated(val) {
            if (val) {
                this.$modal.show('dialog', {
                    text: this.$t('checkout.bill_created'),
                    buttons: [
                        {
                            title: this.$t('common.cancel'),
                            handler: () => {
                                this.$modal.hide('dialog');
                            }
                        },
                        {
                            title: this.$t('common.confirm'),
                            handler: () => {
                                this.$modal.hide('dialog');
                                const oid = this.$store.state.app.shared_oid;
                                const checkoutHost = process.env.VUE_APP_CHECKOUT_HOST;
                                window.open(checkoutHost + '#/summary?oid=' + oid, '_self');
                            }
                        }
                    ]
                });
            }
        }
    },
    methods: {
        ...App.mapActions({
            initData: 'initData',
            getSharedCart: 'get_shared_cart',
            set_msg: 'setToast',
            get_pre_order_status: 'get_pre_order_status'
        }),
        ...App.mapMutations({
            setSharedTimer: 'APP_SET_SHARED_TIMER',
            setSid: 'APP_SET_SID',
            show_menu_nav: 'APP_SHOW_MENU_NAV',
            set_menu_timer: 'SET_MENU_TIMER'
        }),
        ...Menu.mapActions({
            loadMenu: 'initData'
        }),
        hideModal() {
            setTimeout(() => {
                this.$modal.hideAll();
            }, 2000);
        },
        hideRecommend() {
            this.showRecommend = false;
        }
    }
};
</script>
