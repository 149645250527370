// initial state
const state = {
    name: '',
    address: '',
    tel: '',
    is_loading: true,
    err: ''
};

// getters
const getters = {};

// actions
const actions = {
    initData({ commit, rootState }, aid) {
        let aj_host = rootState.apiHost;
        fetch(aj_host + '/weapp/user/address/get?aid=' + aid, {
            method: 'GET'
        })
            .then((res) => {
                return res.text();
            })
            .then((res) => {
                let res_data = JSON.parse(res).data;

                if (res_data.code === 100000) {
                    commit('INIT_EDITADDRESS_DATA', {
                        name: res_data.data[0].uname,
                        address: res_data.data[0].uaddress,
                        tel: res_data.data[0].utel
                    });
                }
            });
    },
    doSave({ commit, rootState }, post_data, router) {
        let aj_host = rootState.apiHost;
        let uname = post_data.name;
        let utel = post_data.tel;
        let uaddress = post_data.address;
        let aid = post_data.aid;
        let uid = post_data.uid;

        let temp_router = post_data.router;

        if (uname == '' || utel == '' || uaddress == '') {
            commit('SET_ERR_MSG', '数据不能为空');
            return;
        }

        let params = 'aid=' + aid + '&uid=' + uid + '&uname=' + uname + '&utel=' + utel + '&uaddress=' + uaddress;

        fetch(aj_host + '/weapp/user/address/edit?' + params, {
            method: 'GET'
        })
            .then((res) => {
                return res.text();
            })
            .then((res) => {
                let res_data = JSON.parse(res).data;

                if (res_data.code === 100000) {
                    temp_router.back(-1);
                }
            });
    }
};

// mutations
const mutations = {
    INIT_EDITADDRESS_DATA(state, editaddress_data) {
        state.name = editaddress_data.name;
        state.address = editaddress_data.address;
        state.tel = editaddress_data.tel;
        state.is_loading = false;
    },
    SET_ERR_MSG(state, msg) {
        state.err = msg;
    },
    SET_INPUT_VALUE(state, data) {
        state[data.key] = data.value;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
