<template>
    <div>
        <div class="detail_mask position-absolute"></div>
        <div class="detail_container">
            <div class="detail_layer recommend_layer centered" style="min-height: 0; max-height: 100%">
                <div class="detail_close" @click="closeFun"></div>
                <img class="recommend-img" v-lazy="recommendData['recommend_popup_pic_url']" />
            </div>
            <div class="detail-background"></div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.detail_mask {
    background-color: black;
    height: 100%;
    width: 100%;
    opacity: 0.6;
    z-index: 1;
}

.recommend_layer {
    width: 100%;
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.detail_container .detail_close {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAiCAMAAAANmfvwAAAAWlBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////9ZMre9AAAAHXRSTlMAEs76FrJ+bMfv7Mi0qD05BvEhoGBaaVKfYVnzIyC2q60AAAEKSURBVDjLjZRZloMgFERLH444Rk3UpPa/zT7YJIFWG+uL4R7eDBypcdalSKnnUeFITSL8SJJmB9wqc561qutUmxm6uvnE2rNIndejtGA/uURK1n/Mq5pMXULu2OkuX2alPHCgh3C1nvbMcKiM/a/PFWucqGa15YOFOkNUQZOf5O1UnkefmPP8HUgCKBH7iGYcWSLmhtjb0XD2wjDuYrMxYmYGl/EJZByg2cJjPAItNUoqeIxHQLGEsIPHeAQ6yhUkbCjsbowhGHQ4dYtJsd3mJwVwy6i/ZdROGcPNEG6pq42J6by9p/CQXB01a+vJIo38gX1O8PTaj/3r/8+DSXOcqGWIzRcUD4vr2A8eSxYWvARjnwAAAABJRU5ErkJggg==');
    background-repeat: no-repeat;
    background-size: 100%;
    width: 35px;
    height: 35px;
    position: absolute;
    right: 2px;
    top: 2px;
    background-color: #000;
    border-radius: 50%;
}

.recommend-img {
    width: 100%;
    min-height: 250px;
}
</style>

<script>
export default {
    name: 'RecommendLayer',
    props: ['recommendData', 'closeFun']
};
</script>
