import Vue from 'vue';
import App from './views/App.vue';
import store from './store/index.js';
import './assets/scss/main.scss';
import router from './router';
import VueLazyLoad from 'vue-lazyload';
import VModal from 'vue-js-modal';
import i18n from './locales';
import './kits/bee';
Vue.use(VueLazyLoad, {
    preLoad: 1.3,
    attempt: 1,
    loading: './assets/images/image-loading.svg'
});
Vue.use(VModal, { dialog: true });
Sentry.init({
    dsn: 'https://fbe87b12fc4f489784a33c38380b707e@o478067.ingest.sentry.io/5526686',
    integrations: [
        new Sentry.Integrations.Vue({
            Vue,
            tracing: true
        }),
        new Sentry.Integrations.BrowserTracing()
    ],
    environment: 'production',
    denyUrls: [/.*localhost.*/, /.*127.0.0.1.*/],
    debug: false,
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0
});

new Vue({
    el: '#application',
    router,
    store,
    i18n,
    render: (h) => h(App)
});
