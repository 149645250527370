// initial state
const state = {
    is_loading: true,
    content_height: document.body.clientHeight - 70, // WARNING: not working
    now_menu: '',
    menu_dict: {},
    menu_list: [],
    food_dict: {},
    show_food_list: [],
    show_detail: false,
    currentCat: '',
    retryCount: 3
};

// getters
const getters = {};

// actions
const actions = {
    initData({ commit, dispatch, rootState }) {
        commit('SET_IS_LOADING', true);
        let aj_host = rootState.apiHost;
        const sid = rootState.app.sid;
        state.is_loading = true;
        return fetch(aj_host + '/weapp/user/menu/get?sid=' + sid, {
            method: 'GET'
        })
            .then((res) => {
                return res.text();
            })
            .then((res) => {
                //TODO handle empty data error
                const res_data = JSON.parse(res).data;
                const menuData = {
                    show_food_list: [],
                    now_menu: 0,
                    menu_dict: {},
                    menu_list: 0,
                    food_dict: {}
                };
                if (!res_data || res_data.code !== 100000) {
                    // commit('INIT_MENU_DATA', menuData);
                    // dispatch('setCurrentCat');
                    // error when fetching, retry
                    if (state.retryCount > 0) {
                        dispatch('initData', sid);
                        commit('SET_RETRY_COUNT', state.retryCount - 1);
                    }
                    return;
                }
                const { first_menu, menu, menu_list, foods } = res_data.data;
                for (let m in menu) {
                    if (menu[m].is_hide > 0) {
                        delete menu[m];
                    }
                }
                let menu_id = first_menu;
                if (!first_menu || !menu[first_menu] > 0) {
                    menu_id = Object.keys(menu)[0];
                }
                commit('INIT_MENU_DATA', {
                    show_food_list: menu[menu_id].list,
                    now_menu: menu_id,
                    menu_dict: menu,
                    menu_list: menu_list.filter((m) => m.is_hide < 1),
                    food_dict: foods
                });
                dispatch('setCurrentCat');
                commit('SET_RETRY_COUNT', 3);
            })
            .finally(() => {
                state.is_loading = false;
            });
    },
    getDailyLimit({ commit, rootState }, fids) {
        return fetch(rootState.apiHost + '/weapp/user/menu/getDailyLimit?fids=' + fids, {
            method: 'GET'
        })
            .then((res) => {
                return res.text();
            })
            .then((res) => {
                const res_data = JSON.parse(res).data;
                if (!res_data || res_data.code !== 100000) {
                    return [];
                }
                return res_data.data;
            })
            .catch(() => {
                return [];
            });
    },
    doToggleDetail({ commit, rootState }, isSHow) {
        commit('MENU_TOGGLE_DETAIL', isSHow);
    },
    setCurrentCat({ state, commit }) {
        if (state.currentCat !== '') {
            return;
        }
        const menuIds = Object.keys(state.menu_dict);
        if (menuIds.length > 0) {
            commit('SET_CURRENT_CAT', menuIds[0]);
        }
    }
};

// mutations
const mutations = {
    INIT_MENU_DATA(state, res_data) {
        state.show_food_list = res_data.show_food_list;
        state.now_menu = res_data.now_menu;
        state.menu_dict = res_data.menu_dict;
        state.menu_list = res_data.menu_list;
        state.food_dict = res_data.food_dict;
        state.is_loading = false;
    },
    SET_FOODS_DATA(state, _mid) {
        state.show_food_list = state.menu_dict[_mid].list;
        state.now_menu = _mid;
    },
    MENU_TOGGLE_DETAIL(state, show) {
        state.show_detail = show;
    },
    SET_IS_LOADING(state, payload) {
        state.is_loading = payload;
    },
    SET_CURRENT_CAT(stat, cat) {
        state.currentCat = cat;
    },
    SET_RETRY_COUNT(state, count) {
        state.retryCount = count;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
