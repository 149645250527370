import store from '../store/index.js';

const checkDailyLimit = ({ fid, that, count }) => {
    return new Promise((res, rej) => {
        if (count <= 0) {
            res();
            return;
        }
        const food = store.state.menu.food_dict[fid];
        const daily_limit = food.daily_limit;
        const daily_sales_count = food.daily_sales_count;
        if (daily_limit > 0 && daily_limit - daily_sales_count <= 0) {
            that.$modal.show('dialog', {
                text:
                    that.$t('validate.soldout_today'),
                buttons: [
                    {
                        title: that.$t('common.back'),
                        handler: () => {
                            that.$router.push({ name: 'menu' }).catch((e) => {});
                            that.$modal.hide('dialog');
                        }
                    },
                    {
                        title: that.$t('common.confirm'),
                        handler: () => {
                            that.$router.push({ name: 'menu' }).catch((e) => {});
                            that.$modal.hide('dialog');
                        }
                    }
                ]
            });
            rej();
        } else if (daily_limit > 0 && daily_limit - daily_sales_count - count < 0) {
            const left = daily_limit - daily_sales_count;
            that.set_msg({
                msg: that.$t('validate.left_today', {left})
            });
            rej();
        } else {
            res();
        }
    });
};

const checkOnceLimit = ({ fid, that, count }) => {
    const orderFoods = store.state.sharedorderdetail.foods;
    const cart_food_dict = store.getters['app/get_cart_food_dict'];
    return new Promise((res, rej) => {
        if (count <= 0) {
            res();
            return;
        }
        const food = store.state.menu.food_dict[fid];

        const limit = food.sc_order_limit;
        // const fid = food.fid;
        if (limit<=0) {
            res();
        } else {
            /*
             * 修改计算fid对应的数量的判断逻辑
             */
            let countFromOrder =
                orderFoods?.reduce((sum, order) => {
                    const filter_foods = order.foods.filter((food_item) => {
                        return !order.voided && fid === food_item.fid;
                    });
                    if (filter_foods?.length > 0) {
                        filter_foods.forEach((_food) => {
                            sum += parseInt(_food.count);
                        });
                    }
                    return sum;
                }, 0) || 0;
            let countFromCart = cart_food_dict?.[fid]?.mix_fcount || 0;
            if (count + countFromOrder + countFromCart > limit) {
                const translate = store.getters['app/translate'];
                const name = translate('item', food.fname)
                that.set_msg({
                    msg: that.$t('validate.each_party_order_limit', {limit, name})
                });
                rej();
            } else {
                res();
            }
        }
    });
};

const checkOnceSubmitLimit = ({ fid, that, count }) => {
    const cart_food_dict = store.getters['app/get_cart_food_dict'];
    return new Promise((res, rej) => {
        if (count <= 0) {
            res();
            return;
        }
        const food = store.state.menu.food_dict[fid];

        const limit = food.sc_sub_order_limit;
        if (limit<=0) {
            res();
        } else {
            let countFromCart = cart_food_dict?.[fid]?.mix_fcount || 0;
            if (count + countFromCart > limit) {
                const translate = store.getters['app/translate'];
                const name = translate('item', food.fname)
                that.set_msg({
                    msg: that.$t('validate.each_submit_limit', {limit, name})
                });
                rej();
            } else {
                res();
            }
        }
    });
};

export default {
    checkDailyLimit,
    checkOnceLimit,
    checkOnceSubmitLimit,
};
