<template>
    <div>
        <div class="row" @click="choose_food">
            <div class="col-sm-9 pr-0">
                <h1>
                    <span class="item-title text-truncate">
                        {{
                        translate('item', product_item.fname)
                        }}
                    </span>
                    <span v-if="cart_food_dict[product_item.fid] && cart_food_dict[product_item.fid].mix_fcount > 0">
                        (
                        <span style="color: red">{{ cart_food_dict[product_item.fid].mix_fcount }}</span>
                        )
                    </span>
                </h1>
                <div class="pt-3 pb-3 text-truncate" style="color: black; word-break: break-word; line-height: 1.4">
                    <div class="desc_item" v-html="desc"></div>
                </div>
                <h2
                    class="price"
                    :class="{ price_tbd: product_item.is_tbd }"
                    v-if="product_item.fprice || product_item.is_tbd == 1"
                >
                    <div v-if="product_item.is_tbd" class="tbd">TBD</div>
                    <template v-else>
                        <div :class="['fprice', ifShowFpriceOriginal(product_item) && 'promo']">
                            ${{ product_item.fprice }}
                        </div>
                        <div class="fprice_original" v-if="ifShowFpriceOriginal(product_item)">
                            ${{ product_item.fprice_original }}
                        </div>
                    </template>
                    <span class="d-inline-block" v-if="product_item.feature_name && !product_item.addon_id"
                        >with options</span
                    >
                    <span class="d-inline-block" v-if="product_item.addon_id && !product_item.feature_name"
                        >with addons</span
                    >
                    <span class="d-inline-block" v-if="product_item.feature_name && product_item.addon_id"
                        >with addons & options</span
                    >
                </h2>
                <div
                    :class="[
                        'daily_limit',
                        product_item.daily_limit - product_item.daily_sales_count <= 0 && 'sale_out'
                    ]"
                    v-if="product_item.daily_limit > 0 && product_item.is_out_of_stock !== 1"
                >
                    {{ dailyLimitLeft }}
                </div>
                <div class="once_limit" v-if="product_item.sc_order_limit > 0 || product_item.sc_sub_order_limit > 0">
                    {{ onceLimitTip }}
                </div>
                <div class="product-extra" v-if="!is_active">
                    <span>Not available</span>
                </div>
                <div
                    class="product-extra"
                    v-if="item.is_active && product_item.is_out_of_stock == 1"
                    @click="showOutOfStock"
                >
                    <span class="out-of-stock">
                        {{ $t('menuItem.outof_stock') }}
                    </span>
                </div>
            </div>

            <div class="product_item_right">
                <div class="promote_tag" v-if="product_item.promote_tag">{{ $t('food.tag_'+product_item.promote_tag) }}</div>
                <div class="item-img" v-lazy:background-image="getLazyLoadImg(product_item)"></div>
                <div
                    class="plus-wrap"
                    v-if="
                        !outOfStock &&
                        product_item.addon_data.length == 0 &&
                        !(product_item.feature_name && !product_item.addon_id)
                    "
                >
                    <img src="@/assets/img/minus_2.svg" alt="" @click.stop="do_save(-1)" v-if="cartNum > 1" />
                    <img src="@/assets/img/buy_cart_remove.png" alt="" @click.stop="do_save(-1)" v-if="cartNum === 1" />
                    <span class="num" v-if="cartNum > 0">{{ cartNum }}</span>
                    <img src="@/assets/img/plus.svg"  alt="" @click.stop="do_save(1)" />
                </div>
            </div>
        </div>
        <div v-if="product_item.feature_tag" class="food_tag" style="color:green;">
            <div class="img img_leaf"></div>
            <div class="food_tag_text">
                {{feature_tag}}
            </div>
        </div>
        <div v-if="product_item.allergy_tag" class="food_tag">
            <div class="img img_alert"></div>
            <div class="food_tag_text">
                {{allergy_tag}}
            </div>
        </div>
    </div>
</template>

<script>
const Menu = createNamespacedHelpers('menu');
import { lazyLoadImg, LazyLoadImg } from '../kits/tools';
import validates from '@/service/validates.js';
import { createNamespacedHelpers, mapActions, mapGetters, mapMutations, mapState } from 'vuex';
const Orders = createNamespacedHelpers('sharedorderdetail');
export default {
    name: 'MenuItem',
    props: {
        product_item: {
            type: Object,
            required: true
        },
        item: {
            type: Object,
            required: true
        },
        cart_food_dict: {
            type: Object,
            required: true
        },
        language: {
            type: String,
            required: true
        },
        is_active: {
            type: Number, // 1:0
            required: true
        }
    },

    data() {
        return {
            imageLoaded: false,
            // detail_data:{},
            addon_dict: {},
            addon_count_dict: {},
            choose_count: 1,
            feature_name: '',
            note: ''
        };
    },
    created() {
        this.$Lazyload.$on('loaded', () => {
            this.imageLoaded = true;
        });
    },
    computed: {
        ...mapGetters({
            is_vip: 'app/get_if_is_vip',
            cart_food_list: 'app/get_cart_food_list',
            translate: 'app/translate'
        }),
        ...Menu.mapState(['food_dict']),
        ...Orders.mapState({
            orderFoods: 'foods'
        }),
        feature_tag() {
            let tags = this.product_item.feature_tag.split(',');
            tags = tags.map(item=>{
                return this.$t('food_tag.'+item);
            });
            return tags.join(', ');
        },
        allergy_tag() {
            let tags = this.product_item.allergy_tag.split(',');
            tags = tags.map(item=>{
                return this.$t('food_tag.'+item);
            });
            if(this.language==='kor'){
              return tags.join(', ') + ' ' +this.$t('food_tag.may_contain')
            }else {
              return  this.$t('food_tag.may_contain') + ' ' + tags.join(', ')
            }
        },
        outOfStock() {
            if (
                this.product_item.daily_limit > 0 &&
                this.product_item.daily_limit - this.product_item.daily_sales_count <= 0
            ) {
                return true;
            }
            return false;
        },
        dailyLimitLeft() {
            let left = this.product_item.daily_limit - this.product_item.daily_sales_count;
            if (left > 0) {
                return this.$t('menuItem.today_left', {count: left})
            } else {
                return this.$t('menuItem.soldout_today')
            }
        },
        onceLimitTip() {
            if(this.product_item.sc_order_limit>0) {
                return this.$t('menuItem.limit_per_table', {limit:this.product_item.sc_order_limit});
            }else if(this.product_item.sc_sub_order_limit>0) {
                return this.$t('menuItem.limit_per_submit', {limit:this.product_item.sc_sub_order_limit});
            }
            return '';
        },
        desc() {
            const desc = this.translate('food_desc', this.product_item.fdesc)
            return desc.replaceAll('\n', '<br>').replace(/(<br\s?\/?>)+/g, '<br>');
        },
        cartNum() {
            return this.cart_food_dict[this.product_item.fid]?.mix_fcount || 0;
        }
    },
    mounted() {},
    methods: {
        ...mapMutations({
            setCartEditLock: 'app/APP_SET_CART_EDIT_LOCK'
        }),
        choose_option(feature_name) {
            this.feature_name = feature_name;
        },
        ...mapActions({
            // loadMenuFood: 'menu/initData',
            edit_food: 'app/edit_cart_food',
            edit_cart_food_count: 'app/edit_cart_food_count',
            set_msg: 'app/setToast'
        }),
        ifShowFpriceOriginal(product_item) {
            return product_item.fprice_original !== '' && parseFloat(product_item.fprice_original) > 0;
        },
        async do_save(action) {
            if(action===1) {
                window['__logger__'] && window['__logger__']({
                    view: 0,
                    click: 1,
                    module_id: 'item_plus',
                    item_id: this.product_item.fid
                });
            }

            const countOld = this.cart_food_dict[this.product_item.fid]?.mix_fcount || 0;
            let type = 'set';
            if (action === 1 && countOld === 0) {
                type = 'add';
            }
            let _food_data = JSON.parse(JSON.stringify(this.product_item));

            if (action === 1 && this.product_item.is_out_of_stock == 1) {
                this.set_msg({
                    msg: this.$t('menuItem.item_outof_stock')
                });
                return;
            }

            if (action === 1) {
                /*
                 * 此处去掉 countOld，因为在判断逻辑里面已经加了购物车的数量。
                 * changed by @wudi at 2021-09-21
                 */
                await validates.checkDailyLimit({ fid: _food_data.fid, that: this, count: action });
                await validates.checkOnceLimit({ fid: _food_data.fid, that: this, count: action });
                await validates.checkOnceSubmitLimit({ fid: _food_data.fid, that: this, count: action });
            }

            this.setCartEditLock({ lock: true });
            let saveData = {
                cartid: this.$store.state.app.shared_cart_id,
                tid: this.$store.state.app.shared_tid,
                router: this.$router,
                uid: this.$store.state.app.uid,
                is_vip: this.is_vip
            };
            if (type === 'add') {
                _food_data.fcount = 1;
                _food_data.fprice_vip = _food_data.fprice_vip ? _food_data.fprice_vip : '';
                saveData = {
                    ...saveData,
                    type: 'add',
                    food_data: _food_data,
                    uname: this.$store.state.app.uname,
                    extra_data: {
                        feature_name: this.product_item.feature_default_value || '',
                        note: '',
                        addon_names: '',
                        addon_prices: '',
                        addon_counts: '',
                        add_count: 1
                    }
                };
                await this.edit_food(saveData);
                this.setCartEditLock({ lock: false });
            } else if (type === 'set') {
                const selfName = this.$store.state.app.uname;
                let uname = '';
                let countNew = 0;
                let foodCart = this.cart_food_list.filter(
                    (item) => item.uname === selfName && item.fid === _food_data.fid
                )?.[0];
                if (!foodCart) {
                    foodCart = this.cart_food_list.filter((item) => item.fid === _food_data.fid)?.[0];
                }
                uname = foodCart.uname;
                countNew = foodCart.fcount;
                if (action === 1) {
                    countNew++;
                } else {
                    countNew--;
                }

                let tip100000 = null;
                if (action === 1 && countNew > 1) {
                    tip100000 = (name) => {
                        this.set_msg({
                            msg: this.$t('menuItem.add_1', {name})
                        });
                    };
                }
                if (action === -1 && countNew > 0) {
                    tip100000 = (name) => {
                        this.set_msg({
                            msg: this.$t('menuItem.reduce_1', {name})
                        });
                    };
                }

                // const uname = this.cart_food_list.filter((item) => item.fid === _food_data.fid)?.[0]?.uname;
                saveData = {
                    ...saveData,
                    uname,
                    type: 'set',
                    fid: _food_data.fid,
                    fname: _food_data.fname,
                    fprice: _food_data.fprice,
                    fprice_vip: _food_data.fprice_vip || '',
                    set_count: countNew,
                    feature_name: '',
                    addon_names: '',
                    addon_prices: '',
                    addon_counts: '',
                    note: '',
                    tip100000
                };
                await this.edit_cart_food_count(saveData);
                this.setCartEditLock({ lock: false });
            }
        },
        getLazyLoadImg(product_item) {
            return lazyLoadImg(product_item.fpic);
        },
        choose_food() {
            const { product_item, is_active } = this;

            window['__logger__'] && window['__logger__']({
                view: 0,
                click: 1,
                module_id: 'item',
                item_id: product_item.fid
            });

            if (product_item.is_out_of_stock == 1) {
                this.showOutOfStock();
                return;
            }

            if (
                this.product_item.daily_limit > 0 &&
                this.product_item.daily_limit - this.product_item.daily_sales_count <= 0
            ) {
                this.set_msg({
                    msg: this.$t('menuItem.soldout_today_msg')
                });
                return;
            }

            const food_data = {
                food_data: product_item,
                is_active,
                is_out_of_stock: product_item.is_out_of_stock
            };
            // this.$emit('choose_food', food_data);
            this.$router.push({ path: `/food?id=${this.product_item.fid}` });

            window['__logger__'] && window['__logger__']({
                view: 0,
                click: 1,
                module_id: 'enter_itemPage',
                item_id: product_item.fid
            });
        },
        showOutOfStock() {
            this.$emit('showOutOfStock');
        }
    }
};
</script>

<style lang="scss" scoped>
.row{
    margin-right: 0;
    >*:first-child{
        flex-grow: 1;
        width: 100px;
    }
}
.food_tag{
    display: flex;
    align-items: center;
    font-size:14px;
    margin-top:6px;
    .food_tag_text{
        line-height: normal;
        overflow:hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
    }
    img{
        margin-right: 7px;
        height: 13px;
    }
}
.food_tag .img_leaf{
    mask: url(../assets/images/leaf.svg) no-repeat;
    -webkit-mask: url(../assets/images/leaf.svg) no-repeat;
    background-color: green;
}
.food_tag .img_alert{
    mask: url(../assets/images/alert.svg) no-repeat;
    -webkit-mask: url(../assets/images/alert.svg) no-repeat;
    background-color: #d40606;
}
.food_tag .img{
    flex-shrink: 0;
    margin-right: 7px;
    height: 13px;
    width: 13px;
    background-size: contain;
    mask-size: 14px;
    -webkit-mask-size: 14px;
}
.product_item_right{
    position: relative;
    width: 100px;
    margin-left: 15px;
    .promote_tag{
        position: absolute;
        right: 0;
        padding: 5px 8px;
        border-radius: 3px;
        background-color: var(--themeColor);
        color: var(--themeColorFont)
    }
}
.product-extra {
    margin-top: 5px;
}
.text-truncate {
    overflow: visible;
    white-space: pre-wrap;
}
/* .addcart {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 2px solid #333;
    position: absolute;
    bottom: 0px;
    right: 0px;
    font-size: 20px;
    font-weight: 600;
} */
.out-of-stock {
    color: #b12704;
    font-weight: bold;
}

h1 {
    font-size: 16px;
    display: flex;
}

h2 {
    font-size: 12px;
}
.item-title {
    padding-right: 0;
    line-height: 1.4;
    width: 80%;
    flex-grow: 1;

    overflow:hidden;
    text-overflow:ellipsis;
    display:-webkit-box;
    /*autoprefixer: off;*/
    /*autoprefixer: on;*/
    -webkit-box-orient:vertical;
    -webkit-line-clamp:3;
}
.plus-wrap {
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: 13px;
    .num {
        margin: 0 10px;
        font-size: 15px;
    }
    img {
        width: 25px;
    }
}
.price {
    display: flex;
    align-items: center;
    .fprice {
        margin-right: 8px;
        &.promo {
            font-size: 14px;
            color: rgb(157, 5, 5);
            margin-right: 10px;
        }
    }
    .fprice_original {
        text-decoration: line-through;
        margin-right: 10px;
    }
}
.desc_item {
    word-break: break-all;
    word-break: break-all;
    line-height: 1.3;
    margin-bottom: 6px;

    overflow:hidden;
    text-overflow:ellipsis;
    display:-webkit-box;
    /*autoprefixer: off;*/
    /*autoprefixer: on;*/
    -webkit-box-orient:vertical;
    -webkit-line-clamp:3;
    &:last-child {
        margin-bottom: 0;
    }
}
.daily_limit,
.once_limit {
    margin-top: 6px;
    font-size: 14px;
    &.sale_out {
        color: #b12704;
        font-weight: bold;
        font-size: 14px;
    }
}
</style>
