import md5 from "md5";
import querystring from "querystring";

const BEE_SEPERATOR = '|';
const MAX_STORE_SIZE = 2000;
const debug = /(127.0.0.1)|(localhost)|(192.168.43.232)|(client.minitable.link)/.test(window.location.hostname);
const collect_host = debug ? 'collect.minitable.link' : 'collect.minitable.net';

function sendByFakeImage(sendURL) {
    if (process.env.DEBUG === 'true') {
        return;
    }

    var img = new Image(1,1);
    img.onload = img.onerror = img.onabort = function() {
        img.onload = img.onerror = img.onabort = null;
        img = null;
    };
    img.src = sendURL;
}

function type(obj) {
    if (obj == null) return "null";
    return Object.prototype.toString.call(obj).slice(8, -1).toLowerCase() || "object";
}

function doEscape(str) {
    return encodeURIComponent(String(str).replace(/(?:\r\n|\r|\n)/g,"<CR>"));
}

const version = '1';
const business = 'scantable';
const uploadURL = '//' + collect_host + '/weapp/admin/tracking/create_tracking_data';
const plugins = [];
const inited = false;

function bee_send(data) {
    let url = uploadURL.indexOf('?') < 0 ? uploadURL + '?' : uploadURL;
    url += 'business=' + business;
    url += version ? '&v=' + version : '';

    let leftVarsLen = MAX_STORE_SIZE - url.length;
    let sendList = [];
    let sid = window.localStorage.getItem('client_sid') || '';
    let session_id = window.sessionStorage.getItem('session_id') || '';
    // let now_url = window.location.href && window.location.href.split('?')[0];
    let now_url = window.location.href || '';

    url += '&url=' + doEscape(now_url);

    if (sid) {
        data['sid'] = sid;
    }

    if (session_id) {
        data['bid'] = session_id;
    } else {
        session_id = md5(new Date().getTime() + sid + navigator.userAgent);
        window.sessionStorage.setItem('session_id', session_id);
        data['bid'] = session_id;
    }

    Object.keys(data).map(key => {
        let value = data[key];
        let query;
        if (type(value) === "array") {
            query = "&" + key + "=" + doEscape(value.join(BEE_SEPERATOR));
        } else {
            query = "&" + key + "=" + doEscape(value);
        }
        sendList.push(query);
    });

    let queryParams = '';
    sendList.sort((a, b) => a.length < b.length ? -1 : 1).forEach(qry => {
        if (queryParams.length + qry.length > leftVarsLen) {
            if (!queryParams) { 
                queryParams = qry;
                qry = "";
            }
            sendByFakeImage(url + queryParams);
            queryParams = "";
        }
        queryParams += qry;
    });
    queryParams && sendByFakeImage(url + queryParams);
}

document.body.onclick = function(e) {
    let all_nodes = e.path;
    for (let i = 0; i < all_nodes.length; i++) {
        if (all_nodes[i] && all_nodes[i].getAttribute && all_nodes[i].getAttribute('data-bee')) {
            let send_data = all_nodes[i].getAttribute('data-bee');
            send_data = querystring.decode(send_data);
            send_data.click = 1;
            send_data.view = 0;

            bee_send(send_data);
            break;
        }
    }
};

window['__logger__'] = bee_send;
