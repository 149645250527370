// initial state
const state = {
};

// getters
const getters = {};

// actions
const actions = {
    checkClosedReason({ commit, rootState }, post_data) {
        let aj_host = rootState.apiHost;
        console.log(aj_host)
        fetch(aj_host + '/weapp/user/table/closed/reason?sid=' + post_data.sid + '&tid=' + post_data.tid, {
            method: 'GET'
        })
            .then((res) => {
                return res.text();
            })
            .then((res) => {
                let res_data = JSON.parse(res).data;

                if (res_data.code === 100000 && res_data.data.reason == 'combine') {
                    post_data.router && post_data.router.replace({
                        path: '/sub/selecttable?tid=' + res_data.data.direct_to + '&sid=' + post_data.sid
                    });
                }
            });
    }
};

// mutations
const mutations = {
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
