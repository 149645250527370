<template>
    <div class="outer" v-if="visible">
        <img src="@/assets/img/buy_cart_remove.png" class="close" @click="visible = false" />
        <div class="main">
            <div class="title">{{ $t('common.search_item') }}</div>
            <input type="text" class="search_input" v-model="fname" />
            <div class="list">
                <MenuItem
                    class="menu"
                    v-for="(product_item, index) in list"
                    :key="index + '-' + product_item.fid"
                    :product_item="product_item"
                    :language="language"
                    :item="{ is_active: true }"
                    :cart_food_dict="cart_food_dict"
                    :is_active="1"
                    @showOutOfStock="showOutOfStock"
                />
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import { createNamespacedHelpers } from 'vuex';
import { mapState, mapGetters } from 'vuex';
import MenuItem from './MenuItem';

const App = createNamespacedHelpers('app');

export default {
    name: 'SearchFood',
    components: {
        MenuItem
    },
    data() {
        return {
            visible: false,
            list: [],
            fname: '',
            timmer: null
        };
    },
    computed: {
        ...App.mapState(['store_data', 'multi_lang']),
        ...mapState({
            sid: (state) => state.app.sid,
        }),
        ...mapGetters({
            language: 'app/get_language',
            cart_food_dict: 'app/get_cart_food_dict'
        })
    },
    methods: {
        showOutOfStock() {
            this.set_msg({ msg: this.$t('food.outof_stock') });
        },
        show() {
            this.visible = true;
        },
        hide() {
            this.visible = false;
        }
    },
    watch: {
        fname(val) {
            if (val === '') {
                this.list = [];
                return;
            }
            val = val.toLowerCase();
            const multiLang_food = this.multi_lang[this.sid].item;
            let foods_filtered = [];
            Object.keys(multiLang_food).forEach(key=>{
                const food = multiLang_food[key];
                if(food[this.language] && food[this.language].toLowerCase().includes(val)) {
                    foods_filtered.push(food);
                }else if(!food[this.language] && food.eng.toLowerCase().includes(val)){
                    foods_filtered.push(food);
                }
            });
            foods_filtered = foods_filtered.map(item=>item.eng).join(',').toLowerCase();

            const foodDict = this.$store.state.menu.food_dict;
            const foods = Object.values(foodDict);
            this.list = foods.filter((food) => {
                return foods_filtered.includes(food.fname.toLowerCase());
            });
        },
        $route(to, from) {
            this.visible = false;
        }
    }
};
</script>

<style scoped lang="scss">
.outer {
    position: fixed;
    background-color: rgba(255, 255, 255, 0.9);
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 999;
    .close {
        position: absolute;
        top: 20px;
        right: 20px;
        z-index: 99999;
        width: 30px;
        opacity: 1;
    }
    .main {
        width: 95vw;
        height: 100vh;
        padding-top: 50px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        .title {
            font-size: 18px;
            text-align: center;
            font-weight: bold;
        }
        .search_input {
            display: block;
            width: 90%;
            padding-left: 10px;
            border: 1px solid rgb(177, 177, 177);
            border-radius: 5px;
            height: 34px;
            margin: 20px auto;
        }
        .list {
            flex-grow: 1;
            overflow: auto;
            padding-bottom: 200px;
        }
    }
}
</style>
