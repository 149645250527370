<template>
    <header v-if="!is_select">
        <div class="container">
            <div class="row">
                <div class="col-2 d-flex pr-0">
                    <div
                        slot="left"
                        v-if="!hideButton && !is_select && !is_thanks && !is_preoder && !is_error && !isFood"
                    >
                        <button class="navbar-toggler" id="navbarSideButton" @click="toggle_leftnav" type="button">
                            <span v-for="i in [1, 2, 3]" :key="i" class="icon-bar"></span>
                        </button>
                    </div>
                    <div v-if="isFood" @click="go_back" class="ml-2">
                        <a class="go-back">←</a>
                    </div>
                </div>
                <div class="col-8 text-center">
                    <!-- <div class="mt-header">{{ app_title || sname }}</div> -->
                    <div class="mt-header">{{ headerTitle }}</div>
                </div>
                <div class="col-2 text-right header_right">
                    <img src="@/assets/images/search.svg" class="search_icon" @click="showSearch" />
                    <div class="dropdown" v-if="is_menu">
                        <a
                            class="mt-globe"
                            id="dropdownMenuLink"
                            data-toggle="dropdown"
                            @click="show_choose_language"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                        </a>
                        <span class="badge badge-success">
                            {{ $t('header.lang') }}
                        </span>
                        <div class="dropdown-menu-wrapper" v-if="show_global_zone" aria-labelledby="dropdownMenuLink">
                            <a
                                v-for="(item, index) in multiLang" :key="index"
                                class="dropdown-item"
                                :class="{ current: language === item.key }"
                                href="#"
                                @click="changeLanguage(item.key)"
                                >{{ item.name }}</a
                            >
                        </div>
                    </div>
                    <div
                        class="cart_icon"
                        slot="right"
                        @click="go_to_cart"
                        ref="header-cart"
                        v-if="
                            !hideButton && !is_select && !is_thanks && !is_preoder && !is_error && !is_menu && !is_cart
                        "
                    >
                        <div class="header-cart"></div>
                        <div class="badge badge-danger">
                            {{ food_count }}
                        </div>
                    </div>
                    <a class="mt-back" v-if="is_cart" @click="go_back"></a>
                </div>
            </div>
            <div class="row d-flex mt-2 justify-content-around header-status" v-if="is_menu">
                <div class="col-3 text-center">
                    <h1>
                        {{ uname.indexOf('@') > -1 ? uname.split('@')[0] : uname.toLowerCase().replace('guest', '') }}
                    </h1>
                    <div class="tb pt-2">{{ $t('header.guest_code') }}</div>
                </div>
                <div class="col-3 text-center">
                    <h1>{{ shared_tname }}</h1>
                    <div class="tb pt-2">
                        <div class="lbl">{{ $t('header.table_code') }}</div>
                    </div>
                </div>
                <div class="col-3 text-center" v-if="verifyCode">
                    <h1>{{ verifyCode }}</h1>
                    <div class="tb pt-2">
                        <div class="lbl">{{ $t('header.code') }}</div>
                    </div>
                </div>
                <div class="col-3 text-center">
                    <h1>{{ user_count }}</h1>
                    <div class="tb pt-2">{{ $t('header.people_join') }}</div>
                </div>
            </div>
        </div>
        <CartLayer v-if="showCartLayer" v-click-outside="{ handler: 'closeCartLayer', exclude: ['header-cart'] }" />
        <div class="join-msg" v-if="hasNewUser" :class="{ 'slide-bottom': hasNewUser }">
            <span class="msg">{{ joinInMsg }}</span>
        </div>
        <!-- currently hided -->
        <RefBanner v-if="false" class="top-banner position-absolute" />
        <Search ref="search" />
    </header>
</template>

<script>
import Vue from 'vue';
import { createNamespacedHelpers } from 'vuex';
import CartLayer from '@/components/CartLayer';
import RefBanner from '@/components/RefBanner';
import Search from '@/components/Search';

let handleClickOutside;

Vue.directive('click-outside', {
    bind(el, binding, vnode) {
        handleClickOutside = (e) => {
            e.stopPropagation();
            const { handler, exclude } = binding.value;
            let clickedOnExcludedEl = false;
            exclude.forEach((refName) => {
                if (!clickedOnExcludedEl) {
                    const excludedEl = vnode.context.$refs[refName];
                    if (excludedEl) {
                        clickedOnExcludedEl = excludedEl.contains(e.target);
                    }
                }
            });
            if (!el.contains(e.target) && !clickedOnExcludedEl) {
                vnode.context[handler]();
            }
        };
        document.addEventListener('click', handleClickOutside);
        document.addEventListener('touchstart', handleClickOutside);
    },

    unbind() {
        document.removeEventListener('click', handleClickOutside);
        document.removeEventListener('touchstart', handleClickOutside);
    }
});

const App = createNamespacedHelpers('app');
export default {
    name: 'Header',
    components: {
        CartLayer,
        RefBanner,
        Search
    },
    data() {
        return {
            show_global_zone: false,
            hasNewUser: false
        };
    },
    computed: {
        ...App.mapState(['showCartLayer', 'store_data']),
        ...App.mapGetters({
            wait_code: 'get_wait_code',
            waiting_status_text: 'get_waiting_status_text',
            user_count: 'get_shared_user_count',
            shared_tname: 'get_shared_tname',
            shared_tstatus: 'get_shared_tstatus',
            shared_tid: 'get_shared_tid',
            slogo: 'get_store_slogo',
            shared_table_pswd: 'get_table_pswd',
            sname: 'get_store_name',
            food_count: 'get_food_count',
            shared_oid: 'get_shared_oid',
            app_title: 'get_app_title',
            language: 'get_language',
            uname: 'get_uname',
            verifyCode: 'get_table_pswd'
        }),
        multiLang() {
            return this.store_data.multi_lang;
        },
        headerTitle() {
            if (this.$route.path === '/menu') {
                return this.sname;
            } else {
                return this.app_title || this.sname;
            }
        },
        is_sub() {
            return this.$route.path.indexOf('/sub/') > -1;
        },
        is_select() {
            return this.$route.path.indexOf('/sub/selecttable') > -1;
        },
        is_thanks() {
            return this.$route.path.indexOf('/sub/thanks') > -1;
        },
        is_menu() {
            return this.$route.path.indexOf('/menu') > -1;
        },
        is_preoder() {
            return this.$route.path.indexOf('/sub/preorder') > -1;
        },
        is_error() {
            return this.$route.path.indexOf('/sub/error') > -1;
        },
        is_cart() {
            return this.$route.path.indexOf('/sub/cart') > -1;
        },
        is_table() {
            return this.$route.path.indexOf('/table') > -1;
        },
        is_invite() {
            return this.$route.path.indexOf('/invite') > -1;
        },
        isFood() {
            return this.$route.path.indexOf('/food') > -1;
        },
        joinInMsg() {
            return this.$t('header.new_join')
        },
        hideButton() {
            return this.$route.path === '/closed_table';
        }
    },
    mounted() {},
    methods: {
        ...App.mapMutations({
            toggle_left_nav: 'APP_TOGGLE_LEFT_NAV',
            toggleCartLayer: 'TOGGLE_CART_LAYER',
            set_language: 'APP_SET_LANGUAGE'
        }),
        showSearch() {
            this.$refs.search.show();
        },
        show_choose_language() {
            this.show_global_zone = !this.show_global_zone;
        },
        changeLanguage(_language) {
            localStorage.setItem('language', _language);
            this.set_language({
                language: _language
            });
            this.show_global_zone = false;
        },
        go_to_cart() {
            if (this.food_count > 0) {
                this.toggleCartLayer();
            } else {
                this.go_back();
            }
        },
        go_back() {
            if(this.isFood) {
                window['__logger__'] && window['__logger__']({
                    view: 0,
                    click: 1,
                    module_id: 'button_click',
                    item_id: 'food_back'
                });        
            }
            this.$router.push({ name: 'menu' }).catch((e) => {});
        },
        closeCartLayer() {
            if (this.showCartLayer) {
                this.toggleCartLayer();
            }
        },
        toggle_leftnav() {
            if (this.shared_tname) {
                this.toggle_left_nav();
            } else {
                return;
            }
        }
    },
    watch: {
        user_count(newCount, oldCount) {
            if (newCount > oldCount && oldCount > 0) {
                this.hasNewUser = true;
                setTimeout(() => {
                    this.hasNewUser = false;
                }, 3000);
            }
        }
    }
};
</script>

<style scoped lang="scss">
.cart_icon {
    position: relative;
    top: -5px;
    flex-shrink: 0;
}
.mt-back {
    position: relative;
    top: -5px;
    flex-shrink: 0;
    -webkit-mask-size: 27px 30px;
    width: 20px;
    height: 30px;
}
.header_right {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    .search {
        color: white;
    }
}
.header-status {
    .tb {
        color: var(--themeColorFont);
        font-size: 12px;
    }

    h1 {
        color: var(--themeColorFont);
        font-size: 16px;
    }
}

.mask-zone {
    width: 100%;
    height: 100%;
    z-index: 9;
    position: fixed;
    top: 0;
    left: 0;
}

.global-zone {
    position: fixed;
    top: 50px;
    right: 8px;
    width: 120px;
    background-color: #fff;
    border: 1px solid #eee;
    border-radius: 10px;
    z-index: 10;
    text-align: center;
}

.header-cart {
    // background-image: url(../assets/images/cart-sprite.png);
    mask: url('../assets/img/buy_cart.svg') no-repeat;
    mask-size: contain;
    background-color: var(--themeColorFont);
    display: block;
    width: 26px;
    height: 22px;
    background-repeat: no-repeat;
    float: right;
    margin-right: 5px;
}

.badge {
    pointer-events: none;
    position: absolute;
    top: 10px;
    min-width: 18px;
    right: 16px;
}

.navbar-toggler {
    cursor: pointer;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;

    .icon-bar {
        display: block;
        width: 20px;
        height: 2px;
        margin-bottom: 4px;
        background-color: var(--themeColorFont);
        position: relative;
        transition: all 0.5s ease-in-out;
    }
}

.join-msg {
    width: 100%;
    text-align: center;
    font-weight: bolder;

    position: absolute;
    top: 80px;
    left: 0;
    z-index: 2;
    padding: 12px;

    .msg {
        background-color: #ff4f36a6;
        padding: 12px;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
        color: white;
        border-radius: 5px;
    }
}

.dropdown {
    position: relative;

    .badge {
        right: 0px;
    }

    .dropdown-menu-wrapper {
        position: absolute;
        top: 20px;
        right: 0;
        z-index: 2;
        margin: 0.125rem 0 0;
        font-size: 1rem;
        color: #212529;
        text-align: left;
        list-style: none;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 0.25rem;
        width: 100px;
        padding: 5px 10px;

        .dropdown-item {
            padding: 0.5rem;
            &.current {
                color: green;
                font-weight: bolder;
            }
            &:hover {
                background-color: var(--themeColor);
                color: var(--themeColorFont);
                // color: white;
            }
        }
    }
}

.go-back {
    // color: #1e2022;
    color: var(--themeColorFont);
    font-size: 2rem;
    display: inline-block;
    margin-top: -0.3rem;
}

.top-banner {
    top: 0;
    left: 0;
    width: 100%;
}
.search_icon {
    margin-right: 10px;
    width: 25px;
    position: relative;
    top: -5px;
    left: -7px;
}
</style>
