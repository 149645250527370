<template>
    <div style="margin: -10px auto 10px; width: 90%; height: 16px" v-if="scrollShow">
        <div class="scroll-wrap" ref="wrap">
            <div class="scroll" :style="styleScroll" ref="content">
                {{ noticeContent }}
            </div>
        </div>
    </div>
</template>
<script>
import { mapState, mapGetters, createNamespacedHelpers } from 'vuex';
const App = createNamespacedHelpers('app');

export default {
    name: 'TipScrollAuto',
    components: {},
    data() {
        return {
            styleScroll: {}
        };
    },
    watch: {
        language() {
            this.setSpeed();
        },
        scrollShow() {
            this.setSpeed();
        },
        store_data() {
            this.setSpeed();
        }
    },
    methods: {
        setSpeed() {
            if (!this.scrollShow) return;
            this.$nextTick(function () {
                const wrapWidth = this.$refs.wrap.clientWidth;
                const contentWidth = this.$refs.content.clientWidth;
                // speed越大越慢
                const speed = this.language === 'eng' ? 20 : 10;
                const duration = (speed * (wrapWidth + contentWidth)) / contentWidth;
                this.styleScroll = {
                    animationDuration: duration + 's',
                    color: this.store_data?.notice?.color || ''
                };
            });
        }
    },
    mounted() {
        this.setSpeed();
    },
    computed: {
        scrollShow() {
            const noticeEnable = this.$store.state.app.store_data.notice?.enable;
            return noticeEnable && /^(menu|subCart)$/.test(this.$route.name);
        },
        noticeContent() {
            const notice = this.$store.state.app.store_data.notice;
            return this.translate('notice_content', notice.eng)
        },
        ...App.mapGetters({
            language: 'get_language',
            translate: 'translate'
        }),
        ...App.mapState(['store_data'])
    }
};
</script>

<style lang="scss" scoped>
@keyframes scrollAuto {
    from {
        transform: translateX(0);
        left: 100%;
    }
    to {
        transform: translateX(-100%);
        left: 0;
    }
}
.scroll-wrap {
    width: 100%;
    position: relative;
    height: 16px;
    overflow: hidden;
    .scroll {
        word-break: keep-all;
        white-space: nowrap;
        position: absolute;
        top: 1px;
        left: 100%;
        animation: scrollAuto 15s infinite;
        font-size: 15px;
        // animation-duration: 1000s;
        animation-timing-function: linear;
    }
}
</style>
