<template>
    <div>
        <div class="nav-mask" v-if="show_left_nav" @click="toggle_left_nav"></div>
        <nav class="left-nav" v-bind:class="{ show_nav: show_left_nav }" role="navigation" aria-hidden="false">
            <div class="navbar-side reveal">
                <!-- Site Logo -->
                <div class="site-logo border-b">
                    <img v-if="slogo" :src="slogo" alt="" title="" />
                    <div class="slogan">{{ sname }}</div>
                </div>
                <!-- .site-logo -->
                <div class="op-row border-b" v-if="shared_tname">
                    <div class="ml-3">
                        <div class="row">
                            <div class="col-5 v-center">
                                <div class="lbl">{{ $t('header.table_code') }}</div>
                            </div>
                            <div class="col-7">
                                <div class="val">{{ shared_tname }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- .op-row -->
                <div class="op-row border-b">
                    <div class="ml-3">
                        <div class="row">
                            <div class="col-5 v-center">
                                <div class="lbl">{{ $t('header.guest_code') }}</div>
                            </div>

                            <div class="col-7">
                                <div class="val">{{ uname.toLowerCase().replace('guest', '') }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- .op-row -->
                <div class="op-row">
                    <div class="ml-3">
                        <div class="row">
                            <div class="col-5 v-center">
                                <div class="lbl">{{ $t('header.people_join') }}</div>
                            </div>
                            <div class="col-7">
                                <div class="val">{{ user_count }}</div>
                            </div>
                        </div>
                    </div>
                    <!-- .op-row -->
                    <!-- Main Menu -->
                    <ul class="main-menu" id="menu-beattie-main">
                        <li
                            v-for="(item, i) in nav_list"
                            @click="choose_nav(item)"
                            :key="i"
                            :class="{ active: selected === item.pagePath }"
                        >
                            <a :class="'menu' + (i + 1)">{{ item.text }}</a>
                        </li>
                    </ul>
                    <!-- .main-menu -->

                    <div class="side-footer">powered by minitable</div>
                    <!-- .side-footer -->
                </div>
            </div>
            <!-- .navbar-side -->
        </nav>
    </div>
</template>
<style lang="scss" scoped>
.nav-mask {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: black;
    opacity: 0.7;
    z-index: 10;
}

.left-nav {
    background: #fff;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    bottom: 0;
    font-size: 14px;
    overflow-y: auto;
    padding: 0px 0 40px;
    position: fixed;
    top: 0;
    transform: translateX(-100%);
    transition: transform 0.15s cubic-bezier(0.4, 0, 0.2, 1);
    width: 280px;
    z-index: 10;
}

ul {
    padding-inline-start: 0;

    li {
        &.active {
            background: var(--themeColor);
            color: var(--themeColorFont);
        }
    }
}

.show_nav {
    transform: translateX(0);
    transition-duration: 235ms;
}

.left-nav-title span {
    line-height: 22px;
    vertical-align: top;
}
</style>
<script>
import { mapState, mapGetters, mapMutations } from 'vuex';

export default {
    name: 'LeftNav',
    data() {
        return {
            selected: this.$route.path
        };
    },
    watch: {
        $route: function () {
            this.selected = this.$route.path;
        }
    },
    methods: {
        choose_nav: function (_data) {
            if (_data.key != 'feedback') {
                this.$router.push({ path: _data.pagePath }).catch((e) => {});
                this.toggle_left_nav();
            } else {
                this.toggle_left_nav();
                window.open('/consumer_fb?sid=' + this.sid + '&tname=' + this.shared_tname);
            }
        },
        ...mapMutations({
            toggle_left_nav: 'app/APP_TOGGLE_LEFT_NAV'
        })
    },
    computed: {
        ...mapGetters({
            wait_code: 'app/get_wait_code',
            is_vip: 'app/get_if_is_vip',
            user_count: 'app/get_shared_user_count',
            shared_tname: 'app/get_shared_tname',
            shared_tid: 'app/get_shared_tid',
            slogo: 'app/get_store_slogo',
            shared_table_pswd: 'app/get_table_pswd',
            sname: 'app/get_store_name',
            food_count: 'app/get_food_count',
            shared_oid: 'app/get_shared_oid',
            show_left_nav: 'app/get_if_show_left_nav',
            language: 'app/get_language',
            uname: 'app/get_uname',
        }),
        ...mapState({
            sid: (state) => state.app.sid,
            shared_tname: (state) => state.app.shared_tname
        }),
        nav_list(){
            return [
                {
                    pagePath: '/menu',
                    text: this.$t('sideMenu.order'),
                    key: 'order'
                },
                {
                    pagePath: '/sub/cart',
                    text: this.$t('sideMenu.cart'),
                    key: 'cart'
                },
                {
                    pagePath: '/sub/sharedorderdetail',
                    text: this.$t('sideMenu.placed_orders'),
                    key: 'detail'
                },
                {
                    pagePath: '/invite',
                    text: this.$t('sideMenu.invite'),
                    key: 'invite'
                },
                {
                    pagePath: '/feedback',
                    text: this.$t('sideMenu.feedback'),
                    key: 'feedback'
                }
            ]
        } 
    }
};
</script>
