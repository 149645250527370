<template>
    <div class="menu-type-group text-center">
        <div
            class="menu-type-item"
            v-for="(cat, i) in menu_dict"
            :id="'category_swiper_item_' + i"
            :class="{ active: currentCat === i }"
            :key="i"
        >
            <div class="cat-name-wrap" style="width: 100%">
                <div
                    :data-catId="i"
                    class="color-yellow cat-name"
                    style="width: 100%; color: #000; font-size: 13px; font-weight: bold; white-space: pre-line"
                    :key="language"
                >
                    <template v-if="language==='eng'">
                        <div class="cat-name-1">
                        </div>
                        <div class="cat-name-2">
                        </div>
                    </template>
                    <template v-else>
                        {{ translate('category', cat.name) }}
                    </template>
                </div>
            </div>
            <div
                class="cat-imag"
                @click="gotoCategory(i)"
                :style="{ backgroundImage: `url(${getCateImage(i)})` }"
            ></div>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const Menu = createNamespacedHelpers('menu');
const App = createNamespacedHelpers('app');
export default {
    name: 'CategorySwiper',
    computed: {
        ...Menu.mapState(['is_loading', 'menu_dict', 'currentCat']),
        ...App.mapGetters({ 
            language: 'get_language',
            translate: 'translate'
        })
    },
    data() {
        return {
            catTimmer: null,
            wrapEle: null,
            autoScroll: true
        };
    },

    methods: {
        ...Menu.mapMutations({
            setCurrentCat: 'SET_CURRENT_CAT'
        }),
        render_menuName(){
            this.catTimmer = setInterval(() => {
                const catNameEles = document.querySelectorAll('.cat-name');
                let catNameEle_width = 0;
                if(catNameEles.length>0 && catNameEles[0].offsetWidth>0) {
                    clearInterval(this.catTimmer);
                    for(let index=0,len=catNameEles.length;index<len;index++) {
                        const catNameEle = catNameEles[index];
                        const catId = catNameEle.dataset.catid;
                        const catName = this.menu_dict[catId].name;
                        if(!catNameEle_width) {
                            catNameEle_width = catNameEles[index].offsetWidth;
                        }
                        const catNameArr = catName.split(' ');
                        const catName1Ele = catNameEles[index].querySelector('.cat-name-1');
                        const catName2Ele = catNameEles[index].querySelector('.cat-name-2');
                        for(let index2=0,len2=catNameArr.length;index2<len2;index2++) {
                            const front = catNameArr.slice(0, len2-index2).join(' ');
                            const back = catNameArr.slice(len2-index2, len2).join(' ');
                            catName1Ele.innerHTML = front;
                            if(catName1Ele.offsetWidth<=catNameEle_width){
                                catName2Ele.innerHTML = back;
                                if(catName2Ele.offsetWidth > catNameEle_width) {
                                    let line2_ = back;
                                    if(catName1Ele.offsetWidth / catNameEle_width < 0.5) {
                                        let line1 = front;
                                        let line2 = back;
                                        for(let index3=1,len3=line2.length;index3<len3;index3++) {
                                            let front = line2.slice(0, index3);
                                            let line1_tmp = line1 + ' ' + front;
                                            catName1Ele.innerHTML = line1_tmp;
                                            if(catName1Ele.offsetWidth > catNameEle_width) {
                                                line1 = line1_tmp.slice(0, line1_tmp.length-2) + '-';
                                                line2 = line2.slice(index3-2, len3);
                                                catName1Ele.innerHTML = line1;
                                                catName2Ele.innerHTML = line2;
                                                line2_ = line2;
                                                break;
                                            }
                                        }
                                    }
                                    if(catName2Ele.offsetWidth > catNameEle_width) {
                                        for(let index4=0,len4=line2_.length; index4<len4; index4++) {
                                            let front = line2_.slice(0, len4-index4);
                                            catName2Ele.innerHTML = front;
                                            if(catName2Ele.offsetWidth <= catNameEle_width) {
                                                front = line2_.slice(0, len4-index4-1)+'...';
                                                catName2Ele.innerHTML = front;
                                                break;
                                            }
                                        }
                                    }
                                }
                                break;
                            }else if(len2-index2===1) {
                                const firstWord = catNameArr[0];
                                for(let index3=0,len3=firstWord.length;index3<len3;index3++) {
                                    let front = firstWord.slice(0, len3-index3);
                                    let firstWord_back = firstWord.slice(len3-index3, len3);
                                    catName1Ele.innerHTML = front;
                                    if(catName1Ele.offsetWidth<=catNameEle_width){
                                        front = firstWord.slice(0, len3-index3-1)+'-';
                                        catName1Ele.innerHTML = front;
                                        firstWord_back = firstWord.slice(len3-index3-1, len3);
                                        const line2 = firstWord_back + ' ' + back;
                                        for(let index4=0,len4=line2.length; index4<len4; index4++) {
                                            let front = line2.slice(0, len4-index4);
                                            catName2Ele.innerHTML = front;
                                            if(catName2Ele.offsetWidth <= catNameEle_width) {
                                                if(index4===0) {
                                                    break;
                                                }else {
                                                    front = line2.slice(0, len4-index4-1)+'...';
                                                    catName2Ele.innerHTML = front;
                                                    break;
                                                }
                                            }
                                        }
                                        break;
                                    }
                                }
                                break;
                            }else {
                                catName1Ele.innerHTML = '';
                            }
                        }
                        catName1Ele.style.left = '50%';
                        catName2Ele.style.left = '50%';
                        catName1Ele.style.marginLeft = -catName1Ele.offsetWidth / 2 + 'px';
                        catName2Ele.style.marginLeft = -catName2Ele.offsetWidth / 2 + 'px';
                    }
                }
            }, 100);
        },
        getCateImage(catId) {
            const mpic = this.menu_dict[catId].mpic;
            if (mpic) {
                return mpic;
            }

            let pic = '';
            const foodList = this.menu_dict[catId].list;
            if (foodList[0] && foodList[0].fpic) {
                pic = foodList[0].fpic;
            }
            return pic;
        },
        gotoCategory(id) {
            this.autoScroll = false;
            this.setCurrentCat(id);
            this.$nextTick(() => {
                this.autoScroll = true;
            });
        }
    },
    mounted() {
        this.wrapEle = document.getElementById('swiper');

    },
    watch: {
        language: {
            deep: false,
            immediate: true,
            handler: function (val) {
                if(val==='eng') {
                    this.render_menuName();
                }        
            }
        },
        currentCat(val) {
            if (this.autoScroll) {
                const offset = document.getElementById('category_swiper_item_' + val).offsetLeft;
                this.wrapEle.scrollTo(offset - 20, 0);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~swiper/swiper-bundle.css';
.text-truncate {
    overflow: visible;
    white-space: pre-wrap;
}
.menu-type-group {
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    white-space: nowrap;
    padding: 18px 0;
    background-color: #fff;
    display: flex;
    position: relative;
}

.menu-type-item {
    width: 90px;
    height: 140px;
    margin: 0 4px;
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    border: 1px solid #b2b2b2;
    border-radius: 16px;
    overflow: hidden;

    &.active {
        border-color: var(--themeColor);
        border-width: 2px;
    }

    .cat-name-wrap {
        display: flex;
        flex-grow: 1;
        align-items: center;
        justify-content: center;
        padding: 5px;
        // height: 44px;
        overflow: hidden;
        .cat-name {
            position: relative;
            font-size: 12px;
            height: 32px;
            word-break: break-all;
            white-space: break-spaces;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;

            -webkit-box-orient: vertical;
            -webkit-box-pack: center;
            -webkit-box-align: center;
            box-pack: center;
            box-align: center;

            -webkit-line-clamp: 2;
            line-height: 1.3;
            .cat-name-1, .cat-name-2{
                position: absolute;
                left: 0;
                white-space: nowrap;
                text-align: center;
            }
            .cat-name-2{
                top: 14px;
            }
        }
    }

    .cat-imag {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 92px;
        border-radius: 15px;
        flex-shrink: 0;
    }
}
</style>
