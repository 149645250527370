<template>
    <div class="ref-banner" v-if="showRef" @click="hideRef">
        <div class="close-banner">x</div>

        <!-- <a :href="refBanner.url" target="_blank"><img :src="refBanner.image" alt="ref to earn" /></a> -->
        <a :href="refBanner.url" target="_blank" class="ref-link">{{ refText }} </a>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    name: 'RefBanner',
    data() {
        return {
            // TODO maybe get these data from api
            refBanner: {
                image: require('../assets/img/ref-banner.png'),
                url: 'https://www.surveymonkey.com/r/V7FBT5G'
            },
            showRef: true
        };
    },
    computed: {
        ...mapGetters({
            language: 'app/get_language'
        }),
        refText() {
            return this.$t('refBanner.refer')
        }
    },
    mounted() {
        this.showRef = localStorage.showRef !== '1';
    },

    methods: {
        hideRef() {
            this.showRef = false;
            localStorage.showRef = 1;
        }
    }
};
</script>

<style lang="scss" scoped>
.ref-link {
    color: #ff4f36;
    background-color: white;
    width: 100%;
    display: block;
    text-align: center;
    height: 100%;
    line-height: 50px;
}
.close-banner {
    position: absolute;
    top: 2px;
    right: 2px;
    font-size: 20px;
    border-radius: 50%;
    border: 1px solid black;
    height: 20px;
    width: 20px;
    line-height: 17px;
    text-align: center;
    font-weight: bolder;
    color: black;
}

img {
    width: 100vw;
}
</style>
