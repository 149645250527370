<template>
    <div class="cart-layer">
        <div class="list-item" :class="{ 'no-img': !item.fpic }" v-for="(item, index) in cart_food_list" :key="index">
            <div class="li-c">
                <div class="row mx-0">
                    <div class="col-7 px-0">
                        <h3 class="color-black">
                            {{ translate('item', item.fname) }}
                        </h3>
                    </div>
                    <div class="col-2 px-0 text-center">x {{ item.fcount }}</div>
                    <div class="col-3 text-right px-0">
                        <h2 class="text-right">
                            <span> ${{ getItemPrice(item) }}</span>
                            <!-- <span class="text-danger" v-if="is_vip && item.fprice_vip">
                                {{ (item.fprice_vip * item.fcount).toFixed(2) }}
                            </span> -->
                        </h2>
                    </div>
                </div>
                <div class="addons pt-1" v-if="item.addon_names">
                    <div
                        v-for="(addon_name_item, addon_index) in item.addon_names.split('@@')"
                        v-bind:key="addon_name_item"
                    >
                        <div
                            v-for="(item_name, item_index) in addon_name_item.split('|')"
                            :key="item_index"
                            class="cat"
                        >
                            <div class="d-flex justify-content-between">
                                <span>
                                    {{ getItemText(item, item_name, item_index, addon_index) }}
                                </span>
                                <span> ${{ getItemAddonSumPrice(item, item_index, addon_index) }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <a class="cta mt-1 mb-0 style4" @click="gotoCart">{{ $t('cart.goto_cart') }}</a>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const App = createNamespacedHelpers('app');
export default {
    name: 'CartLayer',
    computed: {
        ...App.mapGetters({
            cart_food_list: 'get_cart_food_list',
            language: 'get_language',
            is_vip: 'get_if_is_vip',
            translate: 'translate'
        })
    },
    methods: {
        ...App.mapMutations({
            toggleCatLayer: 'TOGGLE_CART_LAYER'
        }),
        getUserName(name) {
            if (!name) {
                return `GUEST`;
            }
            return name.toLowerCase().replace('guest', '#');
        },
        getItemAddonSumPrice(item, item_index, addon_index) {
            return (
                item.addon_counts.split('@@')[addon_index].split('|')[item_index] *
                item.addon_prices.split('@@')[addon_index].split('|')[item_index]
            ).toFixed();
        },

        getItemText(item, item_name, item_index, addon_index) {
            const addOnNum = item.addon_counts.split('@@')[addon_index].split('|')[item_index];
            return `${item_name} x ${addOnNum}`;
        },

        getItemPrice(item) {
            return (item.fprice * item.fcount).toFixed(2);
        },
        gotoCart() {
            this.$router.push('/sub/cart');
        }
    }
};
</script>

<style lang="scss" scoped>
.cart-layer {
    position: absolute;
    top: 50px;
    right: 0;
    min-width: 90vw;
    background-color: white;
    padding: 1rem;
    border-radius: 1rem;
    box-shadow: 0 2px 48px 20px rgba(0, 0, 0, 0.06);
    z-index: 1;

    .list-item {
        border-radius: 0;
        padding: 5px;
        cursor: default;
        margin-bottom: 5px;
        border-bottom: 1px dashed grey;
        box-shadow: none;
    }

    .li-c {
        min-height: 0;
    }

    .style4 {
        width: 100%;
        padding: 5px;
    }
}
</style>
