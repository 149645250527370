import Vue from 'vue';
import Vuex from 'vuex';
import createLogger from 'vuex/dist/logger';

import about from './modules/about';
import homepage from './modules/homepage';
import menu from './modules/menu';
import orders from './modules/orders';
import orderdetail from './modules/orderdetail';
import address from './modules/address';
import editaddress from './modules/editaddress';
import app from './modules/app';
import selecttable from './modules/selecttable';
import sharedorderdetail from './modules/sharedorderdetail';
import landingpre from './modules/landingpre';
import feedback from './modules/feedback';
import food from './modules/food';
import closed from './modules/closed';

// import actions from './actions/index.js';
// import mutations from './mutations/index.js';
// import * as getters from './getters/index.js';

Vue.use(Vuex);

const debug = /(127.0.0.1)|(localhost)|(192.168.43.232)/.test(window.location.hostname);
const state = {
    debug,
    apiHost: debug ? 'http://127.0.0.1:5858' : ''
    // apiHost: debug ? 'http://192.168.43.232:5858' : ''
};

// const debug = process.env.NODE_ENV !== 'pro'
// const debug = window.location.host === '127.0.0.1' ? true : false;

export default new Vuex.Store({
    modules: {
        app,
        about,
        homepage,
        menu,
        orders,
        orderdetail,
        address,
        editaddress,
        selecttable,
        sharedorderdetail,
        landingpre,
        feedback,
        food,
        closed
    },
    state,
    strict: state.debug
    // plugins: state.debug ? [createLogger()] : []
});
