import fetchApi from '../../kits/newFetch';
import Vue from 'vue';
import i18n from '../../locales';
import food from '@/store/modules/food';
import axios from 'axios';

let lock_aj = false;
let edit_cart_lock_aj = false;

// initial state
const state = {
    sid: window.localStorage.getItem('client_sid') || '',
    uid: window.localStorage.getItem('uid') || '',
    uname: window.localStorage.getItem('uname') || '',
    sname: window.localStorage.getItem('sname') || '',
    store_data: {
        language: window.localStorage.getItem('language') || 'eng',
        notice: {}
    },
    cart_data: {
        cart_food_list: [],
        cart_food_dict: {
            random: new Date().getTime()
        },
        total_price: '0.0',
        total_price_vip: '0.0',
        remark: '',
        user_count: 0,
        total_food_count: 0
    },
    shared_tid: window.localStorage.getItem('shared_tid') || '',
    shared_tname: window.localStorage.getItem('shared_tname') || '',
    shared_tstatus: '',
    shared_oid: window.localStorage.getItem('shared_oid') || '',
    shared_cart_id: window.localStorage.getItem('shared_cart_id') || '',
    shared_foods_timer: -1,
    shared_table_pswd: window.localStorage.getItem('shared_table_pswd') || '',
    msg: '',
    has_new_order: false,
    show_cart_detail: false,
    type: window.localStorage.getItem('table_type') || '',
    show_left_nav: false,
    app_title: '',
    show_menu_nav: false,
    waiting_status_text: '',
    is_vip: 1,
    party_type: window.localStorage.getItem('party_type') || '',
    wait_code: window.localStorage.getItem('wait_code') || '',
    wait_rank: window.localStorage.getItem('wait_rank') || '',
    phone_num: '',
    recommend_popup_setting: {
        recommend_popup_enable: 0,
        recommend_popup_name: '',
        recommend_popup_pic_url: ''
    },
    showCartLayer: false,
    showReviewLayer: false,
    isLoading: false,
    loadingTxt: '',
    menuTimer: -1,
    lastCartItems: 0,
    isSubmitting: false,
    cartEditLock: false,
    // waitForBillTimer: false,
    billCreated: false,
    multi_lang: {}
};

// getters
const getters = {
    get_recommend_popup_setting(state) {
        return state.recommend_popup_setting;
    },
    get_phone_num(state) {
        return state.phone_num;
    },
    get_party_type(state) {
        return state.party_type;
    },
    get_wait_code(state) {
        return state.wait_code;
    },
    get_if_is_vip(state) {
        return state.is_vip;
    },
    get_waiting_status_text(state) {
        return state.waiting_status_text;
    },
    get_if_can_place_order(state) {
        return state.store_data.can_place_order || 0;
    },
    get_if_show_menu_nav(state) {
        return state.show_menu_nav;
    },
    get_app_title(state) {
        return state.app_title;
    },
    get_if_show_left_nav(state) {
        return state.show_left_nav;
    },
    get_if_has_new_order(state) {
        return state.has_new_order;
    },
    get_store_name(state) {
        return state.sname || '';
    },
    get_store_pic(state) {
        return state.store_data.feature_image || '';
    },
    get_food_count(state) {
        return state.cart_data.total_food_count;
    },
    get_cart_food_list(state) {
        return state.cart_data.cart_food_list;
    },
    get_cart_price(state) {
        return state.cart_data.total_price;
    },
    get_cart_price_vip(state) {
        return state.cart_data.total_price_vip;
    },
    get_cart_food_dict(state) {
        return state.cart_data.cart_food_dict;
    },
    get_shared_user_count(state) {
        return state.cart_data.user_count;
    },
    get_shared_tname(state) {
        return state.shared_tname;
    },
    get_shared_tstatus(state) {
        return state.shared_tstatus;
    },
    get_shared_tid(state) {
        return state.shared_tid;
    },
    get_shared_oid(state) {
        return state.shared_oid;
    },
    get_app_msg(state) {
        return state.msg;
    },
    get_table_pswd(state) {
        return state.shared_table_pswd || '';
    },
    get_store_slogo(state) {
        return state.store_data.slogo || '';
    },
    get_language(state) {
        return state.store_data.language || 'eng';
    },
    get_uname(state) {
        let name = state.uname || localStorage.uname;
        if (!name) {
            name = '0';
        }
        return name;
    },
    translate(state) {
        return function (business, key) {
            return state.multi_lang?.[state.sid]?.[business]?.[key]?.[state.store_data.language] || key
        }
    },
    get_quick_review_enable(state) {
        return state.store_data.quick_review_enable || 0;
    },
    get_show_review_layer(state) {
        return state.showReviewLayer;
    },
};

// actions
const actions = {
    initData({ commit, rootState }) {
        if (!state.sid) {
            return;
        }
        let aj_host = rootState.apiHost;
        fetch(aj_host + '/weapp/user/store/get?sid=' + state.sid, {
            method: 'GET'
        })
            .then((res) => {
                return res.text();
            })
            .then((res) => {
                res = JSON.parse(res);
                if (res.data.code === 100000) {
                    let _store_data = res.data.data.store_data;
                    // delete _store_data.language;
                    let _recommend_data = res.data.data.recommend_data;
                    let multi_lang = res.data.data.multi_lang;
                    document.title = _store_data.sname;
                    commit('APP_SET_MULTI_LANG', multi_lang);
                    commit('APP_INIT_STORE_DATA', _store_data);
                    commit('APP_INIT_OTHER_DATA', {
                        recommend_popup_setting: _recommend_data
                    });
                }
            });
    },
    quitTable({ commit, rootState }, post_data) {
        let aj_host = rootState.apiHost;
        fetch(
            aj_host +
                '/weapp/user/sharedcart/quit?uid=' +
                post_data.uid +
                '&tid=' +
                post_data.tid +
                '&cartid=' +
                post_data.cartid,
            {
                method: 'GET'
            }
        )
            .then((res) => {
                return res.text();
            })
            .then((res) => {
                res = JSON.parse(res);
                if (res.data.code === 100000) {
                    commit('APP_SHARED_CART_CLOSED', {
                        router: post_data.router
                    });
                } else {
                    // commit('APP_SET_MSG', {
                    //     msg: res.data.msg
                    // });
                }
            });
    },
    get_phone_by_tid({ dispatch, commit, rootState }, data) {
        let aj_host = rootState.apiHost;
        return fetch(
            aj_host +
                '/weapp/user/sharedcart/get_phone_by_tid?sid=' +
                data.sid +
                '&tid=' +
                data.tid ,
            {
                method: 'GET'
            }
        )
            .then((res) => {
                return res.text();
            })
            .then((res) => {
                res = JSON.parse(res);
                return res.data.data.phone
            });
    },
    joinTable({ dispatch, commit, rootState }, post_data) {
        let aj_host = rootState.apiHost;
        fetch(
            aj_host +
                '/weapp/user/sharedcart/join?sid=' +
                post_data.sid +
                '&uid=' +
                post_data.uid +
                '&tid=' +
                post_data.tid +
                '&pswd=' +
                post_data.pswd +
                '&uname=' +
                post_data.uname +
                '&type=' +
                post_data.type +
                '&people_num=' +
                post_data.people_num +
                '&tname=' +
                post_data.tname +
                '&phone_num=' +
                encodeURIComponent(post_data.phone_num),
            {
                method: 'GET'
            }
        )
            .then((res) => {
                return res.text();
            })
            .then((res) => {
                res = JSON.parse(res);
                if (res.data.code === 100000) {
                    window.localStorage.setItem('uid', res.data.data.uid);
                    window.localStorage.setItem('uname', res.data.data.uname);
                    window.localStorage.setItem('shared_tid', post_data.tid);
                    post_data.tname && window.localStorage.setItem('shared_tname', post_data.tname);
                    window.localStorage.setItem('shared_cart_id', res.data.data.cartid);
                    window.localStorage.setItem('shared_oid', res.data.data.shared_oid);
                    window.localStorage.setItem('shared_table_pswd', res.data.data.pswd);
                    window.localStorage.setItem('table_type', post_data.type);
                    window.localStorage.setItem('client_sid', post_data.sid);
                    // localStorage.veryfyCode = post_data.pswd;
                    commit('APP_SET_JOIN_TABLE_INFO', {
                        cartid: res.data.data.cartid,
                        uid: res.data.data.uid,
                        uname: res.data.data.uname,
                        shared_oid: res.data.data.shared_oid,
                        type: post_data.type,
                        phone_num: post_data.phone_num
                    });

                    commit('APP_SET_SHARE_TABLE', {
                        tid: post_data.tid,
                        tname: post_data.tname,
                        pswd: res.data.data.pswd
                    });

                    let temp_timer = setInterval(function () {
                        dispatch('get_shared_cart', { cartid: res.data.data.cartid, router: post_data.router }).then(
                            () => {
                                // commit('someOtherMutation')
                            }
                        );
                    }, 3000);

                    commit('APP_SET_SHARED_TIMER', temp_timer);

                    post_data.router.replace({ path: '/index' });
                } else if (res.data.code === 100010) {
                    // 密码错误
                    window.location.reload();
                }
            });
    },
    get_shared_cart({ commit, state, rootState }, post_data) {
        let aj_host = rootState.apiHost;
        if (!post_data.cartid) {
            console.log('no cartid');
            return;
        }

        if (lock_aj) {
            return;
        }
        lock_aj = true;

        fetch(aj_host + '/weapp/user/sharedcart/get?cartid=' + post_data.cartid + '&type=' + rootState.app.type, {
            method: 'GET'
        })
            .then((res) => {
                return res.text();
            })
            .then((res) => {
                lock_aj = false;
                res = JSON.parse(res);
                if (!res || !res.data) {
                    return;
                }
                if (res.data.code === 100000) {
                    if (res.data.data.updateTime < state.cart_data.updateTime && res.data.data.food_list.length > 0) {
                        return;
                    }

                    window.localStorage.setItem('shared_oid', res.data.data.shared_oid);

                    if (res.data.data.shared_oid !== rootState.app.shared_oid) {
                        commit('APP_SET_HAS_NEW_ORDER', {});
                    }

                    const { food_list, shared_tid, shared_oid } = res.data.data;
                    if(state.shared_tid.indexOf('pre_')===0 && shared_tid.indexOf('pre_')!==0) {
                        commit('APP_SET_LINK_TABLE_INFO', {
                            shared_oid,
                            shared_tid
                        });
                    }

                    // if current data is empty and last time is not, wait till next update
                    if ((!food_list || food_list.length < 1) && state.lastCartItems > 0) {
                        commit('SET_LAST_CART_ITEMS', 0);
                        return;
                    }
                    commit('SET_LAST_CART_ITEMS', food_list.length);

                    commit('APP_SET_CART_DATA', {
                        food_list: res.data.data.food_list,
                        food_dict: res.data.data.food_dict,
                        total_price: res.data.data.total_price,
                        total_price_vip: res.data.data.total_price_vip,
                        user_count: res.data.data.user_count,
                        shared_oid: res.data.data.shared_oid,
                        shared_tid: res.data.data.shared_tid,
                        shared_tstatus: res.data.data.shared_tstatus,
                        shared_tname: res.data.data.shared_tname,
                        is_vip: res.data.data.is_vip
                    });
                } else if (res.data.code === 100010) {
                    // 共享购物车已关闭
                    commit('APP_SHARED_CART_CLOSED', {
                        router: post_data.router
                    });
                } else {
                    console.log(res.data);
                }
            })
            .catch((e) => {
                lock_aj = false;
                console.log(e);
            });
    },
    edit_cart_food_count({ commit, dispatch, rootState }, post_data) {
        const { silence, withoutLock } = post_data;
        let aj_host = rootState.apiHost;
        if (edit_cart_lock_aj && !withoutLock) {
            return;
        }
        edit_cart_lock_aj = true;

        if (post_data.cartid == '' && post_data.router) {
            post_data.router.replace({ path: '/sub/selecttable' });
        }

        let params =
            '' +
            'cartid=' +
            post_data.cartid +
            '&tid=' +
            post_data.tid +
            '&uid=' +
            post_data.uid +
            '&uname=' +
            post_data.uname +
            '&fid=' +
            post_data.fid +
            '&fprice=' +
            post_data.fprice +
            '&fprice_vip=' +
            post_data.fprice_vip +
            '&is_vip=' +
            post_data.is_vip +
            '&fcount=' +
            post_data.set_count +
            '&type=set' +
            '&feature_name=' +
            encodeURIComponent(post_data.feature_name) +
            '&addon_names=' +
            encodeURIComponent(post_data.addon_names) +
            '&addon_prices=' +
            post_data.addon_prices +
            '&addon_counts=' +
            post_data.addon_counts +
            '&note=' +
            post_data.note;

        // 产品要求提交完，马上关闭详情层
        setTimeout(function () {
            commit('APP_TOGGLE_CART_DETAIL', false);
        }, 100);

        fetch(aj_host + '/weapp/user/sharedcart/edit?' + params, {
            method: 'GET'
        })
            .then((res) => {
                return res.text();
            })
            .then((res) => {
                edit_cart_lock_aj = false;

                res = JSON.parse(res);
                if (res.data.code === 100000) {
                    if (post_data.tip100000) {
                        post_data.tip100000(post_data.fname);
                    } else {
                        if (!silence) {
                            const msg = post_data.set_count === 0 ? i18n.t('cart.delete_suc',{fname:post_data.fname}) : i18n.t('cart.set_suc', {fname:post_data.fname});
                            dispatch('setToast', {
                                msg
                            });
                        }
                    }
                    post_data.updateTime = res.data.data.updateTime;
                    commit('APP_NEW_SET_FOOD', post_data);
                } else if (res.data.code === 100010) {
                    // 共享购物车已关闭
                    commit('APP_SHARED_CART_CLOSED', {
                        router: post_data.router
                    });
                } else {
                    if (post_data.tip100000) {
                        post_data.tip100000(res.data.msg);
                    } else {
                        if (!silence) {
                            const msg = post_data.set_count === 0 ? i18n.t('cart.delete_suc',{fname:res.data.msg}) : i18n.t('cart.set_suc', {fname:res.data.msg});
                            dispatch('setToast', {
                                msg
                            });
                        }
                    }
                }
            });
    },
    edit_cart_food({ commit, dispatch, rootState }, post_data) {
        let aj_host = rootState.apiHost;
        if (edit_cart_lock_aj) {
            return;
        }
        edit_cart_lock_aj = true;

        post_data.food_data.uid = post_data.uid;
        post_data.food_data.uname = post_data.uname;
        post_data.food_data.feature_name = post_data.extra_data.feature_name;
        post_data.food_data.addon_names = post_data.extra_data.addon_names;
        post_data.food_data.addon_prices = post_data.extra_data.addon_prices;
        post_data.food_data.addon_counts = post_data.extra_data.addon_counts;
        post_data.food_data.note = post_data.extra_data.note;
        post_data.food_data.add_count = post_data.extra_data.add_count;
        post_data.food_data.addon_total_price = post_data.extra_data.addon_total_price;
        post_data.food_data.is_vip = post_data.is_vip;

        if (post_data.cartid == '' && post_data.router) {
            post_data.router.replace({ path: '/sub/selecttable' });
        }

        // 产品要求提交完，马上关闭详情层
        setTimeout(function () {
            commit('menu/MENU_TOGGLE_DETAIL', false, { root: true });
            post_data.router.push({ path: '/menu' });
        }, 100);

        let params =
            '' +
            'cartid=' +
            post_data.cartid +
            '&tid=' +
            post_data.tid +
            '&uid=' +
            post_data.uid +
            '&uname=' +
            post_data.uname +
            '&fid=' +
            post_data.food_data.fid +
            '&fprice=' +
            post_data.food_data.fprice +
            '&fprice_vip=' +
            post_data.food_data.fprice_vip +
            '&is_vip=' +
            post_data.is_vip +
            '&fcount=' +
            post_data.extra_data.add_count +
            '&type=add' +
            '&feature_name=' +
            encodeURIComponent(post_data.extra_data.feature_name) +
            '&addon_names=' +
            encodeURIComponent(post_data.extra_data.addon_names) +
            '&addon_prices=' +
            post_data.extra_data.addon_prices +
            '&addon_counts=' +
            post_data.extra_data.addon_counts +
            '&note=' +
            post_data.extra_data.note;

        fetch(aj_host + '/weapp/user/sharedcart/edit?' + params, {
            method: 'GET'
        })
            .then((res) => {
                return res.text();
            })
            .then((res) => {
                edit_cart_lock_aj = false;

                res = JSON.parse(res);
                if (res.data.code === 100000) {
                    if (post_data.type === 'add') {
                        dispatch('setToast', {
                            msg: i18n.t('cart.add_suc', {fname: post_data.food_data.fname})
                        });

                        post_data.food_data.updateTime = res.data.data.updateTime;
                        commit('APP_NEW_ADD_FOOD', post_data.food_data);
                    } else {
                        commit('APP_DEL_FOOD_INTO_CART', post_data.food_data);
                        dispatch('setToast', {
                            msg: i18n.t('cart.remove_suc', {fname: post_data.food_data.fname})
                        });
                    }
                } else if (res.data.code === 100010) {
                    // 共享购物车已关闭
                    commit('APP_SHARED_CART_CLOSED', {
                        router: post_data.router
                    });
                } else {
                    dispatch('setToast', {
                        msg: i18n.t('cart.set_suc', {fname: post_data.food_data.fname})
                    });
                }
            });
    },
    guestLogin({ commit, rootState }, sid) {
        let aj_host = rootState.apiHost;
        fetch(aj_host + '/weapp/user/guest/login?sid=' + sid, {
            method: 'GET'
        })
            .then((res) => {
                return res.text();
            })
            .then((res) => {
                res = JSON.parse(res);
                if (res.data.code === 100000) {
                    window.localStorage.setItem('uid', res.data.data.uid);
                    window.localStorage.setItem('uname', res.data.data.uname);

                    commit('APP_SET_USER_INFO', {
                        uid: res.data.data.uid,
                        uname: res.data.data.uname
                    });
                }
            });
    },
    submit_cart({ commit, dispatch, rootState }, post_data) {
        // alert(i18n.t('common.back'));
        commit('TOGGLE_LOADING');
        let aj_host = rootState.apiHost;
        fetchApi(
            { commit },
            {
                request_name: 'submit_order',
                params: post_data,
                aj_host
            }
        )
            .then((res) => {
                if (!res.data) {
                    dispatch('setToast', {
                        msg: i18n.t('cart.something_wrong')
                    });
                    return;
                }
                if (res.data.data.status === 'submitted') {
                    dispatch('setToast', {
                        msg: i18n.t('cart.order_submitted_by_others')
                    });
                    post_data.router.push({ name: 'orders' });
                    return;
                }
                commit('APP_CLEAR_CART_DATA', {});
                dispatch('setToast', {
                    msg: i18n.t('cart.ordered_success')
                });

                commit('APP_SET_SHARED_OID', {
                    shared_oid: res.data.data
                });

                //go to shared order...
                post_data.router.push({ name: 'orders' });
            })
            .catch(() => {
                dispatch('setToast', {
                    msg: i18n.t('cart.something_wrong')
                });
            })
            .finally(() => {
                commit('TOGGLE_LOADING');
                commit('SET_SUBMITTING', false);
            });
    },

    checkout({ commit, dispatch, rootState }, post_data) {
        commit('TOGGLE_LOADING');
        const msg = i18n.t('checkout.creating_bill')
        commit('setLoadingTxt', msg);
        let aj_host = rootState.apiHost;
        return fetchApi(
            { commit },
            {
                request_name: 'checkout_order',
                params: post_data,
                aj_host
            }
        )
            .then((res) => {
                if (res.data.code !== 100000) {
                    dispatch('setToast', {
                        msg: i18n.t('common.something_wrong')
                    });
                    return false;
                }
                return 1;
            })
            .catch(() => {
                dispatch('setToast', {
                    msg: i18n.t('common.something_wrong')
                });
            })
            .finally(() => {
                commit('TOGGLE_LOADING', false);
            });
    },

    doToggleDetail({ commit, rootState }, isSHow) {
        commit('APP_TOGGLE_CART_DETAIL', isSHow);
    },
    ifBillCreated({ commit, rootState, state, dispatch }, oid) {
        if (!oid) {
            return Promise.resolve(false);
        }
        let aj_host = rootState.apiHost;
        return fetch(aj_host + '/weapp/user/order/if_bill_created?oid=' + oid, {
            method: 'GET'
        })
            .then((res) => {
                return res.text();
            })
            .then((res) => {
                res = JSON.parse(res);
                if (res.data.code === 100000 && res.data.created) {
                    return true;
                }
                return false;
            })
            .catch(() => {
                dispatch('setToast', {
                    msg: i18n.t('common.something_wrong')
                });
            });
    },
    getOrderTimeByTid({ commit, rootState, state, dispatch }) {
        let aj_host = rootState.apiHost;
        return fetch(aj_host + '/weapp/user/order/get_orderTime_byTid?tid=' + state.shared_tid, {
            method: 'GET'
        })
            .then((res) => {
                return res.text();
            })
            .then((res) => {
                res = JSON.parse(res);
                if (res.data.code === 100000 && res.data.data) {
                    return res.data.data.create_time;
                }
                return 0;
            })
            .catch(() => {
                dispatch('setToast', {
                    msg: i18n.t('common.something_wrong')
                });
            });
    },
    waitForBill({ commit, rootState, state }, tid) {
        commit('TOGGLE_LOADING');
        let aj_host = rootState.apiHost;
        return fetch(aj_host + '/weapp/user/order/wait_for_bill?tid=' + tid, {
            method: 'GET'
        })
            .then((res) => {
                return res.text();
            })
            .then((res) => {
                res = JSON.parse(res);
                if (res.data.code === 100000) {
                    return true;
                } else {
                    return false;
                }
            })
            .finally(() => {
                commit('TOGGLE_LOADING');
            });
    },

    checkWaiting({ commit, rootState }, post_data) {
        let aj_host = rootState.apiHost;
        fetch(
            aj_host +
                '/weapp/user/table/waitstatus?sid=' +
                post_data.sid +
                '&tid=' +
                post_data.tid +
                '&party_type=' +
                post_data.party_type +
                '&rank=' +
                post_data.rank +
                '&language=' +
                post_data.language,
            {
                method: 'GET'
            }
        )
            .then((res) => {
                return res.text();
            })
            .then((res) => {
                res = JSON.parse(res);
                if (res.data.code === 100000) {
                    // let people_num_range = TABLE_STANDARD_MAPPER[res.data.data.standard];
                    // let _count = res.data.data.wait_count;

                    // let _language = rootState.app.store_data.language || 'eng';
                    // let _size = TABLE_SIZE_MAPPER[res.data.data.standard][_language];

                    commit('APP_UPDATE_WAITING_STATUS', {
                        status: res.data.data.wait_msg
                    });
                }
            });
    },
    setToast({ commit }, msg) {
        commit('APP_SET_MSG', msg);
        setTimeout(() => {
            commit('APP_SET_MSG', { msg: '' });
        }, 2000);
    },
    toggleSubmitting({ commit, state }, payload) {
        commit('SET_SUBMITTING', payload);
    },
    get_pre_order_status({ commit, rootState }, post_data) {
        let aj_host = rootState.apiHost;
        fetch(aj_host + '/weapp/user/preorder/link_status/get?sid=' + post_data.sid + '&pre_tid=' + post_data.pre_tid, {
            method: 'GET'
        })
            .then((res) => {
                return res.text();
            })
            .then((res) => {
                res = JSON.parse(res);
                if (res.data.code === 100000) {
                    let link_data = res.data.data.link_data;
                    if (link_data && link_data.table_id) {
                        commit('APP_SET_LINK_TABLE_INFO', {
                            shared_tid: link_data.table_id,
                            shared_oid: link_data.oid
                        });
                    }

                    setTimeout(() => {
                        post_data.cb && post_data.cb();
                    }, 100);
                }
            });
    }
};

// mutations
const mutations = {
    APP_SET_USER_INFO(state, _data) {
        state.uid = _data.uid;
        state.uname = _data.uname;
    },
    APP_SET_CART_EDIT_LOCK(state, _data) {
        state.cartEditLock = _data.lock;
    },
    APP_SET_CART_DATA(state, _data) {
        let food_count = 0;
        for (let i = 0; i < _data.food_list.length; i++) {
            food_count = food_count + _data.food_list[i].fcount;
        }
        state.cart_data.cart_food_list = _data.food_list;
        state.cart_data.cart_food_dict = _data.food_dict;
        state.cart_data.total_price = _data.total_price;
        state.cart_data.total_price_vip = _data.total_price_vip;
        state.cart_data.user_count = _data.user_count;
        state.shared_oid = _data.shared_oid;
        if (_data.shared_tname) {
            state.shared_tname = _data.shared_tname;
        }
        state.shared_tstatus = _data.shared_tstatus;
        if (_data.shared_tid !== state.shared_tid) {
            state.shared_tid = _data.shared_tid;
            localStorage.setItem('shared_tid', _data.shared_tid);
            localStorage.setItem('shared_tname', _data.shared_tname);
        }
        state.is_vip = _data.is_vip;
        state.cart_data.total_food_count = food_count;
    },
    APP_INIT_STORE_DATA(state, _data) {
        if (_data.language_tip_show === 1) {
            delete _data.language;
        } else {
            const lang = localStorage.getItem('language');
            if (lang) {
                _data.language = lang;
            }
        }
        window.localStorage.setItem('sname', _data.sname || '');
        // state.theme_color = _data.theme_color;
        state.app_title = _data.sname;
        state.sname = _data.sname;
        // state.store_data = _data;
        state.store_data = Object.assign({}, state.store_data, _data);

        let red = _data.theme_color.substring(1, 3);
        let green = _data.theme_color.substring(3, 5);
        let blue = _data.theme_color.substring(5, 7);
        red = parseInt('0x' + red);
        green = parseInt('0x' + green);
        blue = parseInt('0x' + blue);
        const gray = red * 0.299 + green * 0.587 + blue * 0.114;

        if (gray > 128) {
            document.documentElement.style.setProperty('--themeColorFont', 'black');
        } else {
            document.documentElement.style.setProperty('--themeColorFont', 'white');
        }
        document.documentElement.style.setProperty('--themeColor', _data.theme_color);
    },
    APP_NEW_SET_FOOD(state, food_data) {
        let res = [];
        let _ori_food_list = JSON.parse(JSON.stringify(state.cart_data.cart_food_list));
        let food_count = 0;
        let plus_count = 0;
        let total_price = 0;
        let total_price_vip = 0;

        for (let i = 0, len = _ori_food_list.length; i < len; i++) {
            let extra_price = 0;
            if (_ori_food_list[i].addon_prices != '') {
                let _addon_items = _ori_food_list[i].addon_prices.split('@@');
                let _addon_counts =
                    (_ori_food_list[i].addon_counts && _ori_food_list[i].addon_counts.split('@@')) || [];

                for (let m = 0, mLen = _addon_items.length; m < mLen; m++) {
                    let _addon_prices = _addon_items[m].split('|');
                    let counts_arr = (_addon_counts[m] && _addon_counts[m].split('|')) || [];
                    for (let j = 0, jLen = _addon_prices.length; j < jLen; j++) {
                        extra_price = parseFloat(extra_price) + parseFloat(_addon_prices[j] * (counts_arr[j] || 0));
                    }
                }
            }
            let _price =
                food_data.is_vip && _ori_food_list[i].fprice_vip
                    ? _ori_food_list[i].fprice_vip
                    : _ori_food_list[i].fprice;

            if (food_data.fid == _ori_food_list[i].fid) {
                if (
                    _ori_food_list[i].feature_name == food_data.feature_name &&
                    _ori_food_list[i].uname == food_data.uname &&
                    _ori_food_list[i].note == food_data.note &&
                    _ori_food_list[i].addon_names == food_data.addon_names &&
                    _ori_food_list[i].addon_counts == food_data.addon_counts
                ) {
                    plus_count = food_data.set_count - _ori_food_list[i].fcount;

                    _ori_food_list[i].fcount = food_data.set_count;
                    if (_ori_food_list[i].fcount > 0) {
                        res.push(_ori_food_list[i]);
                        total_price += _ori_food_list[i].fcount * (parseFloat(_ori_food_list[i].fprice) + extra_price);
                        total_price_vip += _ori_food_list[i].fcount * (parseFloat(_price) + extra_price);
                    }
                } else {
                    res.push(_ori_food_list[i]);
                    total_price += _ori_food_list[i].fcount * (parseFloat(_ori_food_list[i].fprice) + extra_price);
                    total_price_vip += _ori_food_list[i].fcount * (parseFloat(_price) + extra_price);
                }
                food_count = food_count + _ori_food_list[i].fcount;
            } else {
                res.push(_ori_food_list[i]);
                food_count = food_count + _ori_food_list[i].fcount;
                total_price += _ori_food_list[i].fcount * (parseFloat(_ori_food_list[i].fprice) + extra_price);
                total_price_vip += _ori_food_list[i].fcount * (parseFloat(_price) + extra_price);
            }
        }

        // Vue.set(state.cart_data.cart_food_dict[food_data.fid], 'mix_fcount', state.cart_data.cart_food_dict[food_data.fid].mix_fcount + plus_count)

        state.cart_data.cart_food_dict[food_data.fid].mix_fcount =
            state.cart_data.cart_food_dict[food_data.fid].mix_fcount + plus_count;

        state.cart_data.total_food_count = food_count;
        state.cart_data.cart_food_list = res;
        state.cart_data.total_price = parseFloat(total_price).toFixed(2);
        state.cart_data.total_price_vip = parseFloat(total_price_vip).toFixed(2);
        state.cart_data.cart_food_dict.random = new Date().getTime();
        state.cart_data.updateTime = food_data.updateTime;
    },
    APP_NEW_ADD_FOOD(state, food_data) {
        let res = [];
        let _ori_food_list = JSON.parse(JSON.stringify(state.cart_data.cart_food_list));
        let add_flag = false;
        let food_count = 0;

        for (let i = 0, len = _ori_food_list.length; i < len; i++) {
            if (food_data.fid == _ori_food_list[i].fid) {
                add_flag = true;
                if (
                    _ori_food_list[i].feature_name == food_data.feature_name &&
                    _ori_food_list[i].uname == food_data.uname &&
                    _ori_food_list[i].note == food_data.note &&
                    _ori_food_list[i].addon_names == food_data.addon_names &&
                    _ori_food_list[i].addon_counts == food_data.addon_counts
                ) {
                    _ori_food_list[i].fcount = _ori_food_list[i].fcount + food_data.add_count;
                    res.push(_ori_food_list[i]);
                } else {
                    res.push(_ori_food_list[i]);
                    res.push(food_data);
                }
                food_count = food_count + _ori_food_list[i].fcount;
            } else {
                res.push(_ori_food_list[i]);
                food_count = food_count + _ori_food_list[i].fcount;
            }
        }

        if (!add_flag) {
            res.push(food_data);
            food_count = food_count + food_data.add_count;
        }

        if (!state.cart_data.cart_food_dict[food_data.fid]) {
            Vue.set(state.cart_data.cart_food_dict, food_data.fid, {
                mix_fcount: 1,
                price: food_data.fprice,
                price_vip: food_data.fprice_vip
            });
        } else {
            state.cart_data.cart_food_dict[food_data.fid].mix_fcount++;
        }

        let _price = food_data.is_vip && food_data.fprice_vip ? food_data.fprice_vip : food_data.fprice;

        state.cart_data.total_food_count = food_count;
        state.cart_data.cart_food_list = res;
        state.cart_data.total_price = (
            parseFloat(state.cart_data.total_price) +
            (parseFloat(food_data.fprice) + parseFloat(food_data.addon_total_price)) * food_data.add_count
        ).toFixed(2);
        state.cart_data.total_price_vip = (
            parseFloat(state.cart_data.total_price_vip) +
            (parseFloat(_price) + parseFloat(food_data.addon_total_price)) * food_data.add_count
        ).toFixed(2);
        state.cart_data.cart_food_dict.random = new Date().getTime();
        state.cart_data.updateTime = food_data.updateTime;
    },
    APP_ADD_FOOD_INTO_CART(state, food_data) {
        let res = [];
        let _ori_food_list = JSON.parse(JSON.stringify(state.cart_data.cart_food_list));
        let add_flag = false;
        let food_count = 0;

        for (let i = 0, len = _ori_food_list.length; i < len; i++) {
            if (food_data.fid == _ori_food_list[i].fid) {
                add_flag = true;
                food_count = food_count + food_data.fcount;
                if (
                    _ori_food_list[i].feature_default_value == food_data.feature_default_value &&
                    _ori_food_list[i].uname == food_data.uname
                ) {
                    res.push(food_data);
                } else {
                    food_count = food_count + _ori_food_list[i].fcount;
                    res.push(_ori_food_list[i]);
                    res.push(food_data);
                }
            } else {
                res.push(_ori_food_list[i]);
                food_count = food_count + _ori_food_list[i].fcount;
            }
        }

        if (!add_flag) {
            res.push(food_data);
            food_count = food_count + food_data.fcount;
        }

        if (!state.cart_data.cart_food_dict[food_data.fid]) {
            state.cart_data.cart_food_dict[food_data.fid] = {
                mix_fcount: 1,
                price: food_data.fprice,
                price_vip: food_data.fprice_vip
            };
        } else {
            state.cart_data.cart_food_dict[food_data.fid].mix_fcount++;
        }

        state.cart_data.total_food_count = food_count;
        state.cart_data.cart_food_list = res;
        state.cart_data.total_price = (parseFloat(state.cart_data.total_price) + parseFloat(food_data.fprice)).toFixed(
            2
        );
        state.cart_data.cart_food_dict.random = new Date().getTime();
    },
    APP_DEL_FOOD_INTO_CART(state, food_data) {
        let now_count = food_data.fcount;
        let res = [];
        let _ori_food_list = JSON.parse(JSON.stringify(state.cart_data.cart_food_list));
        let food_count = 0;

        for (let i = 0, len = _ori_food_list.length; i < len; i++) {
            if (food_data.fid == _ori_food_list[i].fid) {
                if (
                    _ori_food_list[i].feature_default_value == food_data.default_value &&
                    _ori_food_list[i].uname == food_data.uname
                ) {
                    if (now_count == 1) {
                        continue;
                    } else {
                        food_count = food_count + food_data.fcount;
                        res.push(food_data);
                    }
                } else {
                    food_count = food_count + _ori_food_list[i].fcount;
                    res.push(_ori_food_list[i]);
                }
            } else {
                food_count = food_count + _ori_food_list[i].fcount;
                res.push(_ori_food_list[i]);
            }
        }

        if (state.cart_data.cart_food_dict[food_data.fid]) {
            if (state.cart_data.cart_food_dict[food_data.fid].mix_fcount == 1) {
                state.cart_data.cart_food_dict[food_data.fid] = null;
            } else {
                state.cart_data.cart_food_dict[food_data.fid].mix_fcount--;
            }
        }

        let _price = food_data.is_vip && food_data.fprice_vip ? food_data.fprice_vip : food_data.fprice;

        state.cart_data.cart_food_list = res;
        state.cart_data.total_food_count = food_count;
        state.cart_data.total_price = (parseFloat(state.cart_data.total_price) - parseFloat(food_data.fprice) - parseFloat(food_data.addon_total_price)).toFixed(2);
        state.cart_data.total_price_vip = (parseFloat(state.cart_data.total_price_vip) - parseFloat(_price) - parseFloat(food_data.addon_total_price)).toFixed(2);
        state.cart_data.cart_food_dict.random = new Date().getTime();
    },
    APP_CLEAR_CART_DATA(state) {
        state.cart_data = {
            cart_food_list: [],
            cart_food_dict: {
                random: new Date().getTime()
            },
            total_price: '0.00',
            total_price_vip: '0.00',
            remark: '',
            user_count: state.cart_data.user_count,
            total_food_count: 0
        };
    },
    APP_SET_CART_REMARK(state, remark_data) {
        state.cart_data.remark = remark_data;
    },
    APP_SET_SHARE_TABLE(state, _data) {
        state.shared_tid = _data.tid;
        state.shared_tname = _data.tname;
        state.shared_table_pswd = _data.pswd || '';
    },
    APP_SET_JOIN_TABLE_INFO(state, _data) {
        state.uid = _data.uid;
        state.uname = _data.uname;
        state.shared_cart_id = _data.cartid;
        state.shared_oid = _data.shared_oid;
        state.type = _data.type;
        state.phone_num = _data.phone_num;
    },
    APP_SET_SHARED_TIMER(state, _timer) {
        state.shared_foods_timer = _timer;
    },
    APP_SHARED_CART_CLOSED(state, _data) {
        clearInterval(state.shared_foods_timer);
        state.cart_data = {
            cart_food_list: [],
            cart_food_dict: {
                random: new Date().getTime()
            },
            total_price: '0.00',
            total_price_vip: '0.00',
            remark: '',
            user_count: 0,
            total_food_count: 0
        };
        state.shared_oid = '';
        state.shared_tid = '';
        state.shared_tname = '';
        state.shared_cart_id = '';
        state.shared_table_pswd = '';

        window.localStorage.removeItem('shared_tid');
        window.localStorage.removeItem('shared_tname');
        window.localStorage.removeItem('shared_cart_id');
        window.localStorage.removeItem('shared_oid');
        window.localStorage.removeItem('shared_table_pswd');
        window.localStorage.removeItem('client_sid');
        window.localStorage.removeItem('language');

        if (_data.router) {
            if (_data.router.app.$route.path !== '/sub/selecttable') {
                _data.router.replace({ path: '/sub/thanks' });
            }
        }
    },
    APP_SET_SHARED_OID(state, _data) {
        state.shared_oid = _data.shared_oid;
        state.has_new_order = true;
        window.localStorage.setItem('shared_oid', _data.shared_oid);
    },
    APP_SET_HAS_NEW_ORDER(state, _data) {
        state.has_new_order = true;
    },
    APP_SET_MSG(state, _data) {
        state.msg = _data.msg;
    },
    APP_SET_SID(state, _data) {
        if (_data.sid == window.localStorage.getItem('client_sid')) {
            if (_data.tid != '') {
                if (_data.tid == window.localStorage.getItem('shared_tid')) {
                    return;
                }
            } else {
                return;
            }
        }

        window.localStorage.setItem('client_sid', _data.sid);
        state.sid = _data.sid;

        state.cart_data = {
            cart_food_list: [],
            cart_food_dict: {
                random: new Date().getTime()
            },
            total_price: '0.00',
            total_price_vip: '0.00',
            remark: '',
            user_count: 0,
            total_food_count: 0
        };
        state.shared_oid = '';
        state.shared_tid = '';
        state.shared_tname = '';
        state.shared_cart_id = '';
        state.shared_table_pswd = '';

        window.localStorage.removeItem('shared_tid');
        window.localStorage.removeItem('shared_tname');
        window.localStorage.removeItem('shared_cart_id');
        window.localStorage.removeItem('shared_oid');
        window.localStorage.removeItem('shared_table_pswd');
        window.localStorage.removeItem('language');
    },
    APP_HAVE_READ_NEW_ORDER(state) {
        state.has_new_order = false;
    },
    APP_TOGGLE_CART_DETAIL(state, show) {
        state.show_cart_detail = show;
    },
    APP_TOGGLE_LEFT_NAV(state) {
        state.show_left_nav = !state.show_left_nav;
    },
    APP_SET_APP_TITLE(state, _title) {
        state.app_title = _title;
    },
    APP_SHOW_MENU_NAV(state, _show_menu_nav) {
        state.show_menu_nav = _show_menu_nav;
    },
    APP_UPDATE_WAITING_STATUS(state, _data) {
        state.waiting_status_text = _data.status;
    },
    APP_SET_PARTY_DATA(state, _data) {
        state.party_type = _data.party_type;
        state.wait_code = _data.wait_code;
        state.wait_rank = _data.rank;
        if (_data.phone_num) {
            state.phone_num = _data.phone_num;
        }
    },
    APP_SET_LANGUAGE(state, _data) {
        const { language } = _data;
        localStorage.setItem('language', language);
        state.store_data = Object.assign(state.store_data, _data);
    },
    APP_INIT_OTHER_DATA(state, _data) {
        state.recommend_popup_setting = _data.recommend_popup_setting;
    },
    TOGGLE_CART_LAYER(state) {
        state.showCartLayer = !state.showCartLayer;
    },
    TOGGLE_REVIEW_LAYER(state) {
        state.showReviewLayer = !state.showReviewLayer;
    },
    TOGGLE_LOADING(state, isLoading) {
        if (isLoading === undefined) {
            state.isLoading = !state.isLoading;
        } else {
            state.isLoading = isLoading;
        }
        if (!state.isLoading) {
            state.loadingTxt = '';
        }
    },
    setLoadingTxt(state, txt) {
        state.loadingTxt = txt;
    },
    SET_MENU_TIMER(state, timer) {
        state.menuTimer = timer;
    },
    SET_LAST_CART_ITEMS(state, num) {
        state.lastCartItems = num;
    },
    SET_SUBMITTING(state, isSubmitting) {
        state.isSubmitting = isSubmitting;
    },
    APP_SET_LINK_TABLE_INFO(state, _data) {
        state.shared_oid = _data.shared_oid;
        state.shared_tid = _data.shared_tid;
        state.type = 'scan';

        window.localStorage.setItem('shared_oid', _data.shared_oid);
        window.localStorage.setItem('shared_tid', _data.shared_tid);
        window.localStorage.setItem('table_type', 'scan');
    },
    APP_SET_MULTI_LANG(state, data) {
        const map = {};
        data.forEach(item =>{
            const sid = item.sid;
            const business = item.business;
            if(!map[sid]) {
                map[sid] = {}
            }
            if(!map[sid][business]) {
                map[sid][business] = {}
            }
            map[sid][business][item.eng] = item;
        });
        state.multi_lang = map;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
