const state = {
    detail_data: {
        feature_name: '',
        feature_default_value: 0,
        fprice_vip: 0,
        fprice: 0,
        addon_data: []
    }, //food detail
    mix_fcount: 0,
    from: '',
    pageOpen: true
};

const getters = {};

const actions = {
    setDetailPageData({ commit }, payload) {
        const { detail_data, mix_fcount, from } = payload;
        commit('SET_DETAIL_DATA', detail_data);
        commit('SET_MIX_FCOUNT', mix_fcount);
        commit('SET_FROM', from);
    }
};

const mutations = {
    SET_DETAIL_DATA(state, payload) {
        state.detail_data = { ...payload };
    },
    SET_MIX_FCOUNT(state, payload) {
        state.mix_fcount = payload;
    },
    SET_FROM(state, payload) {
        state.from = payload;
    },
    TOGGLE_PAGE(state, payload) {
        state.pageOpen = payload;
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
};
