<template>
    <div class="footerButtons_wrap" v-if="visible">
        <div class="wrap_back"></div>
        <div :class="['button', callStatusClass]" @click="showServerOptions" v-if="store_data.call_server_enable">
            <div class="button-icon server"></div>
            <div class="text">
                {{ callStatus ? callServerTxt : $t('footer_buttons.call_server') }}
            </div>
            <div class="server_options" v-if="serverOptionsShow" id="server_options_wrap">
                <template v-for="(option, index) in call_server_reason">
                    <div
                        :class="['option', serverOptionsSelect.includes(option) ? 'active' : '']"
                        @click="callServer(1, option)"
                        v-if="option"
                        :key="index"
                    >
                        {{ translate('call_server_reason', option) }}
                    </div>
                </template>
                <div class="option active" @click="callServer(0)">
                    {{ $t('footer_buttons.cancel') }}
                </div>
            </div>
        </div>
        <div :class="['button', menuPage && 'active']" @click="menuClick" id="call_server_button">
            <div class="button-icon footer_icon_menu" :class="[menuPage && 'active']"></div>
            <div class="text"  :class="[menuPage && 'active']">
                {{ $t('footer_buttons.menu') }}
            </div>
        </div>
        <div :class="['button', placedPage && 'active']" @click="go_to_order">
            <div class="button-icon footer_icon_orders"  :class="[placedPage && 'active']"></div>
            <div class="text" :class="[placedPage && 'active']">
                {{ $t('footer_buttons.place_order') }}
            </div>
        </div>
        <div :class="['button']" v-if="quickReviewEnable" @click="showSurvey">
            <div class="button-icon footer_icon_survey"></div>
            <div class="text">
                {{ $t('footer_buttons.survey') }}
            </div>
        </div>
        <div :class="['button', checkGray && 'gray']" v-if="checkShow" @click="goPay">
            <div class="button-icon footer_icon_checkout"></div>
            <div class="text">
                {{ $t('footer_buttons.checkout') }}
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import { mapState, mapActions, mapGetters, mapMutations, createNamespacedHelpers } from 'vuex';
const App = createNamespacedHelpers('app');
const Orders = createNamespacedHelpers('sharedorderdetail');

export default {
    name: 'footerButtons',
    components: {},
    data() {
        return {
            call_server_answer_time: '',
            // placedOrderShow: true,
            serverOptionsSelect_fromApi: '',
            menuShow: false,
            serverOptionsSelect: [],
            serverOptionsShow: false,
            callStatus: 0, // 0- 未呼叫，1- 呼叫中，2- on the way
            timmer: null
        };
    },
    computed: {
        ...App.mapState(['store_data', 'shared_tid', 'sid']),
        ...Orders.mapState(['foods']),
        ...mapGetters({
            language: 'app/get_language',
            translate: 'app/translate',
            quickReviewEnable: 'app/get_quick_review_enable'
        }),
        callStatusClass() {
            switch (this.callStatus) {
                case 0:
                    return '';
                    break;
                case 1:
                    return 'requested';
                    break;
                case 2:
                    return 'onTheWay';
                    break;
                default:
                    return '';
                    break;
            }
        },
        call_server_reason() {
            return this.store_data.call_server_reason.split(',');
        },
        callServerTxt() {
            if (this.callStatus === 1) {
                return this.$t('footer_buttons.request_sent')
            } else if (this.callStatus === 2) {
                return this.$t('footer_buttons.server_ontheway')
            }
            return '';
        },
        checkGray() {
            return this.foods.length === 0;
        },
        checkShow() {
            return this.store_data.checkout_method !== 'no' && this.$store.state.app.type !== 'preorder';
        },
        visible() {
            const path = this.$route.path;
            if (/(\/menu)|(\/sub\/cart)|(\/sub\/sharedorderdetail)/.test(path)) {
                return true;
            }
            return false;
        },
        placedPage() {
            return this.$route.path === '/sub/sharedorderdetail';
        },
        menuPage() {
            return this.$route.path === '/menu';
        }
    },
    created() {
        this.getCallServerStatus();
    },
    mounted() {},
    methods: {
        ...mapActions({
            initData: 'sharedorderdetail/initData',
            goBill: 'sharedorderdetail/goBill',
            set_msg: 'app/setToast',
            checkout: 'app/checkout',
            waitForBill: 'app/waitForBill'
        }),
        ...App.mapMutations({
            toggleReviewLayer: 'TOGGLE_REVIEW_LAYER'
        }),
        bindCallServerClick(e) {
            const serverOptionsWrap = document.getElementById('server_options_wrap');
            if (!serverOptionsWrap.contains(e.target)) {
                this.serverOptionsShow = false;
            }
        },
        goPayConfirm() {
            return new Promise((res, rej) => {
                if (this.foods.length === 0) {
                    this.set_msg({
                        msg: this.$t('footer_buttons.no_order_yet')
                    });
                    reject();
                    return;
                }

                const title = this.$t('footer_buttons.confirm_checout');
                const content = this.$t('footer_buttons.checkout_before_leave');
                this.$modal.show('dialog', {
                    title,
                    text: content,
                    buttons: [
                        {
                            title: this.$t('common.back'),
                            handler: () => {
                                rej();
                                this.$modal.hide('dialog');
                            }
                        },
                        {
                            title: this.$t('common.confirm'),
                            handler: () => {
                                res();
                                this.$modal.hide('dialog');
                            }
                        }
                    ]
                });
            });
        },
        async goPay() {
            await this.goPayConfirm();

            const oid = this.$store.state.app.shared_oid.split('-')[0];

            const _client_host = window.location.origin;
            const checkoutHost = _client_host.replace(/client\./, 'checkout.');

            const foods = this.$store.state.sharedorderdetail.foods;
            let priceSum = 0;
            let taxSum = 0;
            let tipSum = 0;
            let discountSum = 0;
            let serviceFeeSum = 0;
            let processingFeeSum = 0;
            const foods_unVoided = [];

            foods.forEach((item) => {
                if(!item.voided) {
                    foods_unVoided.push(item);
                    const chargeDetail = JSON.parse(item.chargeDetail);
                    priceSum += parseFloat(chargeDetail.price) || 0;
                    taxSum += parseFloat(chargeDetail.tax) || 0;
                    serviceFeeSum += parseFloat(chargeDetail.service_fee) || 0;
                    processingFeeSum += parseFloat(chargeDetail.processing_fee) || 0;
                    tipSum += parseFloat(chargeDetail.tip) || 0;
                    discountSum += parseFloat(chargeDetail.discount) || 0;
                }
            });

            let charge_detail = {
                price: priceSum,
                price_vip: '',
                tax: taxSum,
                service_fee: serviceFeeSum,
                processing_fee: processingFeeSum,
                delivery_fee: '',
                tip: tipSum,
                price_pickup: '',
                discount: discountSum,
                new_discount: '',
                full_coupon_discount: ''
            };
            let data = {
                oid,
                sid: this.$store.state.app.sid,
                tid: this.$store.state.app.shared_tid,
                source: 'scan',
                pay_method: '',
                pay_id: '',
                pay_receipt_url: '',
                checkout_status: 0,
                charge_detail: JSON.stringify(charge_detail),
                item_detail: JSON.stringify(foods_unVoided)
            };

            const method = this.store_data.checkout_method;
            try {
                if (method === 'self') {
                    data.pay_place = 'online';
                    const rsp = await this.checkout(data);
                    if (rsp) {
                        window.open(checkoutHost + '/#/summary?oid=' + oid, '_self');
                    } else {
                        this.set_msg({ msg: this.$t('common.system_error') });
                    }
                } else if (method == 'pos') {
                    const tid = this.$store.state.app.shared_tid;
                    await this.waitForBill(tid);
                    window.open(checkoutHost + '#/wait?oid=' + oid, '_self');
                }
            } catch (error) {
                this.set_msg({ msg: this.$t('common.system_error') });
            }

        },
        menuClick() {
            this.$router.push('/menu');
        },
        go_to_order(e) {
            this.$router.push({ path: '/sub/sharedorderdetail' });
        },
        getCallServerStatus() {
            if(!this.shared_tid) {
                return;
            }
            let query = '?tid=' + this.shared_tid;
            let aj_host = this.$store.state.apiHost;
            fetch(aj_host + '/weapp/user/table/getCallServerStatus' + query, {
                method: 'get'
            })
                .then((res) => {
                    return res.text();
                })
                .then((res) => {
                    res = JSON.parse(res);
                    if (res.data.code === 100000) {
                        const call_server_answer_time = res.data.data.call_server_answer_time;
                        const status = res.data.data.call_server_status;
                        this.serverOptionsSelect_fromApi = res.data.data.call_server_reason;
                        this.callStatus = status;
                        if (status !== 0) {
                            this.call_server_answer_time = call_server_answer_time;
                            this.timmerCancelCall();
                        }
                    }
                })
                .finally(() => {
                    setTimeout(() => {
                        this.getCallServerStatus();
                    }, 30000);
                });
        },
        callServer(status, option = {}) {
            if (status === 1) {
                if (this.serverOptionsSelect.includes(option)) {
                    this.serverOptionsSelect = this.serverOptionsSelect.filter((item) => item !== option);
                    if (this.serverOptionsSelect.length === 0) {
                        status = 0;
                    }
                } else {
                    this.serverOptionsSelect.push(option);
                }
            }
            // let query = '?sid=' + this.sid + '&tid=' + this.shared_tid + '&call_server_status=' + status;
            let query = '?tid=' + this.shared_tid + '&call_server_status=' + status;
            const reason = this.serverOptionsSelect.join(',');
            if (status === 1) {
                query += '&call_server_reason=' + reason;
            }
            let aj_host = this.$store.state.apiHost;
            fetch(aj_host + '/weapp/user/table/call_server' + query, {
                method: 'get'
            })
                .then((res) => {
                    return res.text();
                })
                .then((res) => {
                    res = JSON.parse(res);
                    if (res.data.code === 100000) {
                        this.callStatus = status;
                        if (status === 1) {
                            this.set_msg({
                                msg: this.$t('footer_buttons.call_success')
                            });
                        } else if (status === 0) {
                            if(option!=='timmer') {
                                this.set_msg({ msg: this.$t('footer_buttons.cancel_call') });
                            }
                            this.serverOptionsSelect = [];
                        }
                    } else {
                        if (status === 1) {
                            this.set_msg({
                                msg: this.$t('footer_buttons.call_fail')
                            });
                        } else if (status === 0 && option!=='timmer') {
                            this.set_msg({ msg: this.$t('footer_buttons.cancel_call_fail') });
                        }
                    }
                })
                .catch(() => {
                    if (status === 1) {
                        this.set_msg({
                            msg: this.$t('footer_buttons.call_fail')
                        });
                    } else if (status === 0) {
                        this.set_msg({ msg: this.$t('footer_buttons.cancel_call_fail') });
                    }
                });
        },
        showServerOptions() {
            this.serverOptionsShow = !this.serverOptionsShow;
        },
        // 定时取消呼叫
        timmerCancelCall() {
            const span = this.store_data.call_server_reset_time;
            if (!span || this.callStatus !== 2 || !this.call_server_answer_time) return;
            const left = span * 60 * 1000 - (Date.now() - this.call_server_answer_time);
            if (left > 0) {
                if (this.timmer) {
                    clearTimeout(this.timmer);
                    this.timmer = null;
                }
                this.timmer = setTimeout(() => {
                    this.callStatus = 0;
                    this.callServer(0, 'timmer');
                    clearTimeout(this.timmer);
                    this.timmer = null;
                }, left);
            } else {
                this.callStatus = 0;
                this.callServer(0, 'timmer');
                clearTimeout(this.timmer);
                this.timmer = null;
            }
        },
        initServerSelects() {
            if (this.serverOptionsSelect_fromApi && this.call_server_reason) {
                this.serverOptionsSelect = this.call_server_reason.filter((item) => {
                    return item && this.serverOptionsSelect_fromApi.includes(item);
                });
            }
        },
        showSurvey() {
            this.toggleReviewLayer();
        }
    },
    watch: {
        '$route.path'(val) {
            this.$modal.hide('dialog');
        },
        shared_tid(val) {
            if(val) {
                this.getCallServerStatus();
            }
        },
        serverOptionsShow(val) {
            if (val) {
                setTimeout(() => {
                    document.addEventListener('click', this.bindCallServerClick);
                }, 50);
            } else {
                document.removeEventListener('click', this.bindCallServerClick);
            }
        },
        'store_data.call_server_reset_time'(val) {
            if (val) {
                this.timmerCancelCall();
            }
        },
        serverOptionsSelect_fromApi(val) {
            this.initServerSelects();
        },
        'store_data.call_server_reason'(val) {
            this.initServerSelects();
        }
    }
};
</script>

<style scoped lang="scss">
.footerButtons_wrap {
    position: fixed;
    z-index: 99999;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    height: 81px;
    background-color: white;
    justify-content: space-between;
    box-shadow: 0 7px 12px 0 black;
    .button-icon{
        &.server{
            -webkit-mask: url('../assets/images/footer_buttons/service.svg') no-repeat;
            mask: url('../assets/images/footer_buttons/service.svg') no-repeat;
            -webkit-mask-size: 35px;
            mask-size: 35px;
            height: 30px;
            width: 35px;
        }
        &.footer_icon_menu{
            -webkit-mask: url('../assets/images/footer_buttons/menu_outline.svg') no-repeat;
            mask: url('../assets/images/footer_buttons/menu_outline.svg') no-repeat;
            -webkit-mask-size: 28px;
            mask-size: 28px;
            height: 30px;
            width: 30px;
        }
        &.footer_icon_orders{
            -webkit-mask: url('../assets/images/footer_buttons/orders_outline.svg') no-repeat;
            mask: url('../assets/images/footer_buttons/orders_outline.svg') no-repeat;
            height: 30px;
            width: 30px;
            -webkit-mask-size: 30px;
            mask-size: 30px;
        }
        &.footer_icon_checkout{
            -webkit-mask: url('../assets/images/footer_buttons/checkout.svg') no-repeat;
            mask: url('../assets/images/footer_buttons/checkout.svg') no-repeat;
            height: 30px;
            width: 30px;
            -webkit-mask-size: 30px;
            mask-size: 30px;
        }
        &.footer_icon_survey{
            -webkit-mask: url('../assets/images/footer_buttons/feedback.svg') no-repeat;
            mask: url('../assets/images/footer_buttons/feedback.svg') no-repeat;
            height: 30px;
            width: 30px;
            -webkit-mask-size: 30px;
            mask-size: 30px;
        }
        -webkit-mask-size: 35px;
        mask-size: 35px;
        background-color: #8E8E93;
        background-size: contain;
        z-index: 9;
        &.active{
            background-color: var(--themeColor);
        }
    }
    &::after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background: white;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
    }
    .button {
        margin-right: 5px;
        width: 10px;
        flex-grow: 1;
        border-radius: 6px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 7px 0;

        .button-icon, .text{
            position: relative;
            top: -2px;
        }

        .call_text {
            font-size: 12px;
            color: #8E8E93;
            font-weight: bold;
            position: relative;
            z-index: 999;
            text-align: center;
            line-height: 1.2;
        }

        &.requested {
            &::before {
                background: #e46b0f;
                opacity: 1;
            }
        }
        &.onTheWay {
            &::before {
                background: #48b056;
                opacity: 1;
            }
        }

        &:after {
            position: absolute;
            width: 100%;
            height: 100%;
            background: white;
            z-index: 1;
            content: '';
            display: block;
            border-radius: 6px;
        }

        &.gray {
            &::before {
                background: gray;
            }
        }
        &.active {
            &::before {
                opacity: 1;
                border: 3px solid white;
            }
        }

        &:last-child {
            margin-right: 0;
        }
        img {
            height: 30px;
            width: auto;
            position: relative;
            z-index: 999;
        }
        .text {
            position: relative;
            z-index: 999;
            font-size: 13px;
            color: #8E8E93;
            margin-top: 5px;
            &.active{
                color: var(--themeColor);
                font-weight: bold;
            }
        }
    }
    .server_options {
        min-width: 100%;
        position: absolute;
        bottom: 86px;
        left: 0;
        padding: 3px 10px;
        border-radius: 15px;
        border: 2px solid var(--themeColor);
        background: white;
        .option {
            font-size: 15px;
            color: black;
            padding: 8px 5px;
            text-align: center;
            border-bottom: 1px solid var(--themeColor);
            &:last-child {
                border: none;
            }
            &.active {
                color: var(--themeColor);
                font-weight: bold;
            }
        }
    }
}
</style>
